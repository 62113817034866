import React, { useCallback, useRef, useState } from 'react';
import { TABLE_STATUS_AVAILABLE, TABLE_STATUS_NOT_IN_USE, TABLE_STATUS_OCCUPIED, TABLE_STATUS_PENDING_AVAILABLE, TEST_PRE_CONDITION_SESSION_FINISHED, TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITHOUT_ITEMS, TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITH_ITEMS, TEST_PRE_CONDITION_SESSION_NON_EXISTING, TEST_STATUS_FAILED, TEST_STATUS_NOT_SUPPORTED, TEST_STATUS_PASSED, TEST_STATUS_UNTESTED } from '../shared/Constants';
import PuffLoader from 'react-spinners/PuffLoader';
import { validateListBillItems, validateListSessions, validateListTables, validateSessionExists, validateTableExists } from '../shared/ResponseValidator';
import ReactHtmlParser from "react-html-parser";

function StateCheck(props) {

  const { accountName, setValidated, isValidating, setIsValidating, waiterEnabled, waiterId, testSuite, setTestSuite } = props;
  const isMounted = useRef(true);
  const [isSending, setIsSending] = useState(false)

  const listSessionResults = testSuite.listSessions.preTests.map((preTest, index) => formatPreChecks(preTest, testSuite.listSessions.preTests, index, markListSessionPreTestAsUnsupported));
  const listTablesResults = testSuite.listTables.preTests.map((preTest, index) => formatPreChecks(preTest, testSuite.listTables.preTests, index, markListTablesPreTestAsUnsupported));
  const listBillItemsResults = testSuite.listBillItems.preTests.map((preTest, index) => formatPreChecks(preTest, testSuite.listBillItems.preTests, index, markListListBillItemsPreTestAsUnsupported));
  const getSessionResults = testSuite.getSession.preTests.map((preTest, index) => formatPreChecks(preTest, testSuite.getSession.preTests, index, markGetSessionPreTestAsUnsupported));
  const getTableResults = testSuite.getTable.preTests.map((preTest, index) => formatPreChecks(preTest, testSuite.getTable.preTests, index, markGetTablePreTestAsUnsupported));

  function syntaxHighlight(json, type) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
      var cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          if (type === "request") {
            cls = 'key-request';
          } else {
            cls = 'key-response'
          }
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  }

  function formatPreChecks(preTest, preTests, index, markAsUnsupported) {
    return (
      <div key={index}>
        <div style={{ "display": "inline-block", "width": "100%" }}>
          <div className={`endpoint-check-text no-select ${preTest.result === TEST_STATUS_FAILED ? "failed-text-color" : ""} 
          ${preTest.result === TEST_STATUS_NOT_SUPPORTED ? "not-supported-text-color" : ""}
          ${preTest.result === TEST_STATUS_PASSED ? "passed-text-color" : ""}`}>
            {preTest.response && <div className="link" onClick={() => showJsonPayload(preTests, index)}>{preTest.check}</div>}
            {!preTest.response && preTest.check}
          </div>
          <div className="endpoint-check-actions no-select">
            {preTest.result === TEST_STATUS_FAILED &&
              <button index={index} onClick={markAsUnsupported} className="login100-btn endpoint-checks-not-supported">Mark not supported by EPOS</button>
            }
          </div>
          <div className={`${preTest.enabled === false ? "disabled" : ""}`} style={{ "float": "left", "margin": "5px 0" }}>
            {preTest.result === TEST_STATUS_FAILED &&
              <>
                <div className="pre-test-icon test-case-result test-case-result-failed-image no-select"></div>
                <div className="not-visible">{ReactHtmlParser(preTest.reason)}</div>
              </>
            }
            {preTest.result === TEST_STATUS_PASSED &&
              <div>
                {preTest.successData &&
                  <>
                    <div className="pre-test-icon test-case-result test-case-result-passed-image no-select"></div>
                    <div className="not-visible">{ReactHtmlParser(preTest.successData)}</div>
                  </>
                }
              </div>
            }
            {preTest.result === TEST_STATUS_NOT_SUPPORTED &&
              <>
                {preTest.optional === false &&
                  <>
                    <div className="pre-test-icon test-case-result test-case-result-warning-image no-select"></div>
                    <div className="not-visible">For testing purposes, this can be disabled but Dojo will not certify integrations where this is not supported</div>
                  </>
                }
                {preTest.optional === true &&
                  <>
                    <div className="pre-test-icon test-case-result test-case-result-not-supported-image no-select"></div>
                    <div className="not-visible">Optional test not supported by EPOS</div>
                  </>
                }
              </>
            }
          </div>
        </div>
        {preTest.result !== TEST_STATUS_UNTESTED && preTest.response && preTest.resultActive === true &&
          <>
            <div className="test-case-message-container">
              <div className={"test-case-result-message-data-container"}>
                <div className="test-case-message-heading test-case-message-heading-request no-select">Request</div>
                <pre>{ReactHtmlParser(syntaxHighlight(JSON.stringify(preTest.request, undefined, 4), "request"))}</pre>
              </div>
              <div className={"test-case-result-message-data-container"}>
                <div className="test-case-message-heading test-case-message-heading-response no-select">Response</div>
                <pre>{ReactHtmlParser(syntaxHighlight(JSON.stringify(preTest.response, undefined, 4), "response"))}</pre>
              </div>
            </div>
          </>
        }
      </div>
    )
  }

  function showJsonPayload(preTests, toggleIndex) {
    for (let i = 0; i < preTests.length; i++) {
      if (i === toggleIndex) {
        preTests[i].resultActive = preTests[i].resultActive ? !preTests[i].resultActive : true;
      } else {
        preTests[i].resultActive = false;
      }
    }
    setTestSuite({ ...testSuite });
  }

  function markListSessionPreTestAsUnsupported(e) {
    const preTestIndex = e.target.getAttribute("index");
    testSuite.listSessions.preTests[preTestIndex].result = TEST_STATUS_NOT_SUPPORTED;
    setTestSuite({ ...testSuite });
  }

  function markListTablesPreTestAsUnsupported(e) {
    const preTestIndex = e.target.getAttribute("index");
    testSuite.listTables.preTests[preTestIndex].result = TEST_STATUS_NOT_SUPPORTED;
    setTestSuite({ ...testSuite });
  }

  function markListListBillItemsPreTestAsUnsupported(e) {
    const preTestIndex = e.target.getAttribute("index");
    testSuite.listBillItems.preTests[preTestIndex].result = TEST_STATUS_NOT_SUPPORTED;
    setTestSuite({ ...testSuite });
  }

  function markGetTablePreTestAsUnsupported(e) {
    const preTestIndex = e.target.getAttribute("index");
    testSuite.getTable.preTests[preTestIndex].result = TEST_STATUS_NOT_SUPPORTED;
    setTestSuite({ ...testSuite });
  }
  
  function markGetSessionPreTestAsUnsupported(e) {
    const preTestIndex = e.target.getAttribute("index");
    testSuite.getSession.preTests[preTestIndex].result = TEST_STATUS_NOT_SUPPORTED;
    setTestSuite({ ...testSuite });
  }

  async function handlePreTest(response, preTestIndex, preTests) {
    if (response.result) {
      if (response.result === TEST_STATUS_FAILED) {
        preTests[preTestIndex].request = response.request;
        preTests[preTestIndex].response = response.response;
        preTests[preTestIndex].result = TEST_STATUS_FAILED;
        preTests[preTestIndex].reason = response.reason && !response.reason.errorCode ? response.reason : preTests[preTestIndex].reason;
        preTests[preTestIndex].reason = response.reason.errorCode ? response.reason.errorCode + ": " + response.reason.errorMessage : preTests[preTestIndex].reason;
        preTests[preTestIndex].successData = undefined;
      } else if (response.result === TEST_STATUS_PASSED) {
        preTests[preTestIndex].request = response.request;
        preTests[preTestIndex].response = response.response;
        preTests[preTestIndex].result = TEST_STATUS_PASSED;
        preTests[preTestIndex].rawData = response.data;
        preTests[preTestIndex].successData = response.successData ? response.successData : preTests[preTestIndex].successData;
      }
    }
    else {
      preTests[preTestIndex].result = TEST_STATUS_FAILED;
      preTests[preTestIndex].reason = response.message ? response.message : preTests[preTestIndex].reason;
      preTests[preTestIndex].successData = [];
    }
  }

  const executeListSessionsPreTest = useCallback(async function (preTestIndex, preTests, data, initialSessions) {
    const response = waiterEnabled === true ?
      await validateListSessions(accountName, data, initialSessions, waiterId) :
      await validateListSessions(accountName, data, initialSessions);
    handlePreTest(response, preTestIndex, preTests);
    return response;
  }, [accountName, waiterEnabled, waiterId])

  const executeListTablesPreTest = useCallback(async function (preTestIndex, preTests, data, initalTables) {
    const response = waiterEnabled === true ?
      await validateListTables(accountName, data, initalTables, waiterId) :
      await validateListTables(accountName, data, initalTables);
    handlePreTest(response, preTestIndex, preTests);
    return response;
  }, [accountName, waiterEnabled, waiterId])

  const executeListBillItemsPreTest = useCallback(async function (preTestIndex, preTests, data, initialBillItems) {
    const response = waiterEnabled === true ?
      await validateListBillItems(accountName, data, initialBillItems, waiterId) :
      await validateListBillItems(accountName, data, initialBillItems);
    handlePreTest(response, preTestIndex, preTests);
    return response;
  }, [accountName, waiterEnabled, waiterId])

  const checkListSessions = useCallback(async function () {
    testSuite.currentCheck = testSuite.listSessions.name;
    setTestSuite({ ...testSuite });
    let preTests = testSuite.listSessions.preTests;
    let firstRequestResponse = null;

    testSuite.getSession.notSupportedCount = 0;
    testSuite.recordPayment.notSupportedCount = 0;
    testSuite.listSessions.notSupportedCount = 0;

    for (let i = 0; i < preTests.length; i++) {
      if (i === 0) {
        firstRequestResponse = await executeListSessionsPreTest(i, preTests, preTests[i].data, null);
        if (firstRequestResponse.result === TEST_STATUS_FAILED) {
          testSuite.listSessions.testCases.forEach(test => {
            test.result = TEST_STATUS_NOT_SUPPORTED;
            test.params = ""
            testSuite.listSessions.notSupportedCount++;
          })
        } else {
          testSuite.getSession.preTests[0].data.sessionId = firstRequestResponse.data.sessions[0].id;
          preTests[8].data = { tableNames: [firstRequestResponse.data.sessions[0].tableName] };
        }
      } else {
        if (firstRequestResponse.result === TEST_STATUS_PASSED) {
          const response = await executeListSessionsPreTest(i, preTests, preTests[i].data, firstRequestResponse.data.sessions);
          if (preTests[i].data.isFinished === false && preTests[i].data.isPayable === false) {
            testSuite.getSession.testCases.forEach(test => {
              if (test.preconditions === TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITHOUT_ITEMS) {
                if (response.result === TEST_STATUS_PASSED) {
                  test.result = TEST_STATUS_UNTESTED;
                  test.params = { sessionId: response.data.sessions[0].id }
                } else {
                  testSuite.getSession.notSupportedCount++;
                  test.result = TEST_STATUS_NOT_SUPPORTED;
                  test.params = ""
                }
              }
            })
          }
          if (preTests[i].data.isFinished === false && preTests[i].data.isPayable === true) {
            testSuite.getSession.testCases.forEach(test => {
              if (test.preconditions === TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITH_ITEMS) {
                if (response.result === TEST_STATUS_PASSED) {
                  test.result = TEST_STATUS_UNTESTED;
                  test.params = { sessionId: response.data.sessions[0].id }
                } else {
                  testSuite.getSession.notSupportedCount++;
                  test.result = TEST_STATUS_NOT_SUPPORTED;
                  test.params = ""
                }
              }
            })
          }
          if (preTests[i].data.isFinished === true) {
            testSuite.getSession.testCases.forEach(test => {
              if (test.preconditions === TEST_PRE_CONDITION_SESSION_FINISHED) {
                if (response.result === TEST_STATUS_PASSED) {
                  test.result = TEST_STATUS_UNTESTED;
                  test.params = { sessionId: response.data.sessions[0].id }
                } else {
                  testSuite.getSession.notSupportedCount++;
                  test.result = TEST_STATUS_NOT_SUPPORTED;
                  test.params = ""
                }
              }
            })
          }
          if (preTests[i].data.isPayable === true) {
            testSuite.recordPayment.testCases.forEach(test => {
              if (!test.preconditions.includes(TEST_PRE_CONDITION_SESSION_NON_EXISTING)) {
                if (response.result === TEST_STATUS_PASSED) {
                  test.result = TEST_STATUS_UNTESTED;
                  test.params = { sessionId: response.data.sessions[0].id }
                } else {
                  testSuite.recordPayment.notSupportedCount++;
                  test.result = TEST_STATUS_NOT_SUPPORTED;
                  test.params = ""
                }
              }
            })
            testSuite.getSession.testCases.forEach(test => {
              if (test.preconditions === TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITH_ITEMS) {
                if (response.result === TEST_STATUS_PASSED) {
                  test.result = TEST_STATUS_UNTESTED;
                  test.params = { sessionId: response.data.sessions[0].id }
                } else {
                  testSuite.getSession.notSupportedCount++;
                  test.result = TEST_STATUS_NOT_SUPPORTED;
                  test.params = ""
                }
              }
            })
          }
        } else {
          preTests[i].result = TEST_STATUS_FAILED;
          preTests[i].reason = "Precondition for retrieving a session id from the ListSessions request with no parameters failed";
          preTests[i].successData = [];
        }
      }
    }
  }, [executeListSessionsPreTest, setTestSuite, testSuite])

  const checkListTables = useCallback(async function () {
    testSuite.currentCheck = testSuite.listTables.name;
    setTestSuite({ ...testSuite });
    let preTests = testSuite.listTables.preTests;
    let firstRequestResponse = null;

    testSuite.getTable.notSupportedCount = 0;
    for (let i = 0; i < preTests.length; i++) {
      if (i === 0) {
        firstRequestResponse = await executeListTablesPreTest(i, preTests, preTests[i].data, null);
        if (firstRequestResponse.result === TEST_STATUS_PASSED) {
        testSuite.getTable.preTests[0].data.name = firstRequestResponse.data.tables[0].name
        testSuite.getTable.preTests[0].data.name = firstRequestResponse.data.tables[0].name
          testSuite.getTable.preTests[0].data.name = firstRequestResponse.data.tables[0].name
        } else {
          testSuite.getTable.preTests[0].result = TEST_STATUS_FAILED;
          testSuite.getTable.preTests[0].reason = "Precondition to obtain a table name from the ListTables request with no parameters has failed";
        }
      } else {
        const initalTables = firstRequestResponse.data?.tables ? firstRequestResponse.data.tables : [];
        const response = await executeListTablesPreTest(i, preTests, preTests[i].data, initalTables);
        switch (preTests[i].data) {
          case TABLE_STATUS_AVAILABLE:
          case TABLE_STATUS_PENDING_AVAILABLE:
          case TABLE_STATUS_OCCUPIED:
          case TABLE_STATUS_NOT_IN_USE:
            testSuite.getTable.testCases.forEach(test => {
              if (test.preconditions === preTests[i].data) {
                if (response.result === TEST_STATUS_PASSED) {
                  test.result = TEST_STATUS_UNTESTED;
                  test.params = { name: response.data.tables[0].name }
                } else {
                  preTests[i].result = TEST_STATUS_FAILED;
                  preTests[i].reason = response.reason;
                  preTests[i].successData = [];
                  testSuite.getTable.notSupportedCount++;
                  test.result = TEST_STATUS_NOT_SUPPORTED;
                  test.params = ""
                }
              }
            })
            break;
          default: break;
        }
      }
    }
  }, [executeListTablesPreTest, setTestSuite, testSuite])

  const checkListBillItems = useCallback(async function () {
    testSuite.currentCheck = testSuite.listBillItems.name;
    setTestSuite({ ...testSuite });
    let preTests = testSuite.listBillItems.preTests;
    let firstRequestResponse = null;

    testSuite.listBillItems.notSupportedCount = 0;
    for (let i = 0; i < preTests.length; i++) {
      if (i === 0) {
        firstRequestResponse = await executeListBillItemsPreTest(i, preTests, preTests[i].data, null);
        preTests[1].data.sessionIds = firstRequestResponse.result === TEST_STATUS_PASSED ?
          [firstRequestResponse.data.billItems[0].sessionId, firstRequestResponse.data.billItems[1].sessionId] : preTests[1].data.sessionIds;
        if (firstRequestResponse.result === TEST_STATUS_FAILED) {
          testSuite.listBillItems.testCases.forEach(test => {
            test.result = TEST_STATUS_NOT_SUPPORTED;
            test.params = ""
            testSuite.listBillItems.notSupportedCount++;
          })
        }
      } else {
        if (firstRequestResponse.result === TEST_STATUS_PASSED) {
          await executeListBillItemsPreTest(i, preTests, preTests[i].data.sessionIds, firstRequestResponse.data.billItems);
        } else {
          preTests[i].result = TEST_STATUS_FAILED;
          preTests[i].reason = "Precondition to obtain at least one session id from ListSessions has failed";
          preTests[i].successData = [];
        }
      }
    }
  }, [executeListBillItemsPreTest, setTestSuite, testSuite])

  const checkGetSession = useCallback(async function () {
    testSuite.currentCheck = testSuite.getSession.name;
    setTestSuite({ ...testSuite });
    let preTests = testSuite.getSession.preTests;
    testSuite.getSession.notSupportedCount = 0;
    if (testSuite.listSessions.preTests[0].result === TEST_STATUS_FAILED) {
      testSuite.getSession.preTests[0].result = TEST_STATUS_NOT_SUPPORTED;
      testSuite.getSession.testCases.forEach(test => {
        test.result = TEST_STATUS_NOT_SUPPORTED;
        test.params = ""
        testSuite.getSession.notSupportedCount++;
      })
    } else {
      const response = waiterEnabled === true ? await validateSessionExists(accountName, preTests[0].data.sessionId, waiterId) : await validateSessionExists(accountName, preTests[0].data.sessionId);
      handlePreTest(response, 0, preTests);
    }
  }, [accountName, testSuite, setTestSuite, waiterId, waiterEnabled])

  const checkGetTable = useCallback(async function () {
    testSuite.currentCheck = testSuite.getTable.name;
    setTestSuite({ ...testSuite });
    let preTests = testSuite.getTable.preTests;
    const response = waiterEnabled === true ? await validateTableExists(accountName, preTests[0].data.name, waiterId) : await validateTableExists(accountName, preTests[0].data.name);
    handlePreTest(response, 0, preTests);
  }, [accountName, testSuite, setTestSuite, waiterId, waiterEnabled])

  const checkEPOS = useCallback(async function () {
    setIsValidating(true)
    await checkListSessions()
    await checkListTables()
    await checkListBillItems()
    await checkGetSession()
    await checkGetTable()
    setIsValidating(false)
    setTestSuite({ ...testSuite });
  }, [checkGetTable, checkGetSession, checkListBillItems, checkListSessions, checkListTables, setTestSuite, testSuite, setIsValidating])

  useCallback(async () => {
    if (isSending) return
    setIsSending(true)
    await checkEPOS()
    if (isMounted.current)
      setIsSending(false)
  }, [isSending, checkEPOS])

  function resolvedAllSupportChecks() {
    return testSuite.listSessions.preTests.length !== testSuite.listSessions.preTests.filter(preTest => [TEST_STATUS_PASSED, TEST_STATUS_NOT_SUPPORTED].includes(preTest.result)).length ||
      testSuite.listTables.preTests.length !== testSuite.listTables.preTests.filter(preTest => [TEST_STATUS_PASSED, TEST_STATUS_NOT_SUPPORTED].includes(preTest.result)).length ||
      testSuite.listBillItems.preTests.length !== testSuite.listBillItems.preTests.filter(preTest => [TEST_STATUS_PASSED, TEST_STATUS_NOT_SUPPORTED].includes(preTest.result)).length ||
      testSuite.getSession.preTests.length !== testSuite.getSession.preTests.filter(preTest => [TEST_STATUS_PASSED, TEST_STATUS_NOT_SUPPORTED].includes(preTest.result)).length ||
      testSuite.getTable.preTests.length !== testSuite.getTable.preTests.filter(preTest => [TEST_STATUS_PASSED, TEST_STATUS_NOT_SUPPORTED].includes(preTest.result)).length
  }

  return (
    <div style={{ "width": "100%", "margin": "0 auto", "position": "fixed" }}>
      <div style={{ "width": "100%", "minHeight": "calc(100vh - 100px)", "display": "flex", "alignItems": "center", "justifyContent": "center" }}>
        <div style={{ "width": "calc(100% - 200px)", "background": "#fff", "position": "relative", "padding": "50px 0 90px 0", "maxWidth": "900px" }}>
          <span style={{ "fontSize": "30px", "textTransform": "uppercase", "display": "block" }}>
            EPOS Endpoint Support Checks
          </span>
          <div style={{ "height": "400px", "margin": "20px 0", "color": "grey" }}>
            {isValidating === true &&
              <div>
                <div style={{ "fontSize": "18px" }}>
                  Checking {testSuite.currentCheck}...
                </div>
                <div style={{ "margin": "60px auto", "width": "200px" }}>
                  <PuffLoader
                    color="#2196F3"
                    size={200}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              </div>
            }
            {isValidating === false &&
              <div className="endpoint-check-wrapper">
                <div className="endpoint-check-heading no-select">
                  <b>ListSessions results</b>
                </div>
                <div style={{ "overflow": "hidden" }}>
                  {listSessionResults}
                </div>
                <hr />
                <div className="endpoint-check-heading no-select">
                  <b>ListTables results</b>
                </div>
                <div style={{ "overflow": "hidden" }}>
                  {listTablesResults}
                </div>
                <hr />
                <div className="endpoint-check-heading no-select">
                  <b>ListBillItems results</b>
                </div>
                <div style={{ "overflow": "hidden" }}>
                  {listBillItemsResults}
                </div>
                <hr />
                <div className="endpoint-check-heading no-select">
                  <b>GetSession results</b>
                </div>
                <div style={{ "overflow": "hidden" }}>
                  {getSessionResults}
                </div>
                <hr />
                <div className="endpoint-check-heading no-select">
                  <b>GetTable results</b>
                </div>
                <div style={{ "overflow": "hidden" }}>
                  {getTableResults}
                </div>
              </div>
            }
          </div>
          <div style={{ "width": "390px", "margin": "0 auto" }}>
            <button disabled={isValidating === true} onClick={() => checkEPOS()} className="login100-btn no-select">Run Checks</button>
            <button disabled={resolvedAllSupportChecks()} onClick={() => setValidated(true)} className="login100-btn no-select">Continue to test suite</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export { StateCheck };