import React, { useState } from 'react';
import { TestCaseHeader } from '../shared/TestCaseHeader';
import { TEST_LIST_BILL_ITEMS_TEST_FIRST_SESSION_WITH_BILL, TEST_LIST_BILL_ITEMS_TEST_ONE_NON_EXISTING_SESSION, TEST_LIST_BILL_ITEMS_TEST_ONE_SESSION_WITH_BILL_WITH_ONE_NON_EXISTING_SESSION, TEST_LIST_BILL_ITEMS_TEST_SECOND_SESSION_WITH_BILL, TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL, TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL_WITH_ONE_NON_EXISTING_SESSIONS } from '../shared/Constants';
import { TestCaseRow } from '../shared/TestCaseRow';

function ListBillItems(props) {
  const { testSuite, setTestSuite, runTests, showJsonPayload, filterTestResultsByType } = props;
  const [isSending, setIsSending] = useState(false)

  const testCase = testSuite.listBillItems;
  const testCases = testCase.filteredTestCases ? testCase.filteredTestCases : testCase.testCases;
  const preTests = testCase.preTests;
  const headerVisible = testCase.headerVisible;

  const testCasesDiv = testCases.map((test, index) => {
    switch(test.preconditions) {
      case []: break;
      case TEST_LIST_BILL_ITEMS_TEST_ONE_NON_EXISTING_SESSION:
        test.params = ["00000000-0000-0000-0000-000000000000"] 
        break;
      case TEST_LIST_BILL_ITEMS_TEST_FIRST_SESSION_WITH_BILL: 
        test.params = [preTests[1].data.sessionIds[0]] 
        break;
      case TEST_LIST_BILL_ITEMS_TEST_SECOND_SESSION_WITH_BILL: 
        test.params = [preTests[1].data.sessionIds[1]] 
        break;
      case TEST_LIST_BILL_ITEMS_TEST_ONE_SESSION_WITH_BILL_WITH_ONE_NON_EXISTING_SESSION: 
        test.params = [preTests[1].data.sessionIds[0], "00000000-0000-0000-0000-000000000000"] 
        break;
      case TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL:
        test.params = preTests[1].data.sessionIds
        break;
      case TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL_WITH_ONE_NON_EXISTING_SESSIONS:
        test.params = [preTests[1].data.sessionIds[0], "00000000-0000-0000-0000-000000000000", preTests[1].data.sessionIds[1]] 
        break;
      default: break;
    }
    
    return (
      <TestCaseRow 
        i={index}
        key={index}
        index={test.index}
        params={test.params}
        paramsKey={test.paramsKey}
        preconditions={test.preconditions}
        testCases={testCases}
        requestor={test.requestor}
        request={test.request}
        response={test.response}
        result={test.result}
        requestActive={test.requestActive}
        responseActive={test.responseActive}
        resultActive={test.resultActive}
        reasons={test.reasons}
        isSending={isSending}
        showJsonPayload={showJsonPayload}
        successData={test.successData}
      />
    )
  })

  function setHeaderVisible () {
    testCase.headerVisible = !headerVisible;
    setTestSuite({...testSuite});
  }

  return (
    <div id="list-tables" className="test-case-container">
      <TestCaseHeader
        testCase={testCase}
        setHeaderVisible={setHeaderVisible}
        isSending={isSending}
        setIsSending={setIsSending}
        runTests={runTests}
        filterPassed={testCase.filterPassed}
        filterFailed={testCase.filterFailed}
        filterNotSupported={testCase.filterNotSupported}
        filterTestResultsByType={filterTestResultsByType}
      />
      {headerVisible === true &&
        <div className='test-case-description'>
          <div className="test-case-header-details">
            <div className="pre-tests">
              <hr />
              {testCasesDiv}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export { ListBillItems };