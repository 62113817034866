import React from 'react';
import { TEST_STATUS_UNTESTED } from '../shared/Constants';

function AccountDetails(props) {

  const {accountName, waiterId, waiterEnabled, setConnected, setValidated, testSuite, setTestSuite } = props;

  function disconnect() {
    setConnected(false)
    setValidated(false)
    resetPreTest(testSuite.listSessions)
    resetPreTest(testSuite.listTables)
    resetPreTest(testSuite.listBillItems)
    resetPreTest(testSuite.getSession)
    resetPreTest(testSuite.getTable)
    setTestSuite({ ...testSuite });
  }

  function resetPreTest(preTestSection) {
    for (let i = 0; i < preTestSection.preTests.length; i++) {
      preTestSection.preTests[i].result = TEST_STATUS_UNTESTED;
      preTestSection.preTests[i].response = undefined;
      preTestSection.preTests[i].request = undefined;
      preTestSection.preTests[i].reason = undefined;
      preTestSection.preTests[i].successData = undefined;
    }
  }

  return (
    <div className="options-container">
      <div className="options-header">
        <div style={{"float": "left", "width": "20%", "marginTop": "35px"}}>Account Details</div>

        {waiterEnabled === true && 
        <div style={{"float": "left", "width": "20%", "marginTop": "35px"}}>
          Waiter Id:  <span style={{"textTransform": "uppercase", "color": "black"}}>{waiterId}</span>
        </div>
        }

        <div style={{"float": "left", "width": "40%", "marginTop": "35px"}}>
          Account Name: <span style={{"textTransform": "uppercase", "color": "black"}}>{accountName}</span>
        </div>

        <button className="login100-btn" style={{"float": "right", "width": "200px"}}onClick={disconnect}>Disconnect</button>

        <div className="clear" />
      </div>
    </div>
  );
}

export { AccountDetails };