import React, { useState } from 'react';
import './App.css';
import { Provider } from 'react-redux'
import store from '../store/store';
import { BrowserRouter } from 'react-router-dom';
import { Login } from './Menus/Login';
import { StateCheck } from './Menus/StateCheck';
import { AccountDetails } from './Menus/AccountDetails';
import { TestCases } from './Menus/TestCases';

function HomeComponent() {
  const [accountName, setAccountName] = useState(store.getState().accountName);
  const [waiterId, setWaiterId] = useState(store.getState().waiterId);
  const [waiterEnabled, setWaiterEnabled] = useState(store.getState().waiterEnabled);
  const [testSuite, setTestSuite] = useState(store.getState().testSuite);
  const [notification, setNotification] = useState(store.getState().notification);
  const [connected, setConnected] = useState(store.getState().connected);
  const [validated, setValidated] = useState(store.getState().validated);
  const [isConnecting, setIsConnecting] = useState(store.getState().isConnecting);
  const [isValidating, setIsValidating] = useState(store.getState().isValidating);
  const [isAllRunning, setIsAllRunning] = useState(store.getState().isAllRunning);
  
  return (
    <>
      <Login 
        accountName={accountName}
        setAccountName={setAccountName}
        connected={connected}
        setConnected={setConnected}
        isConnecting={isConnecting}
        setIsConnecting={setIsConnecting}
        waiterEnabled={waiterEnabled}
        setWaiterEnabled={setWaiterEnabled}
        waiterId={waiterId}
        setWaiterId={setWaiterId}
        notification={notification}
        setNotification={setNotification}
      />
      {connected === true &&
        <AccountDetails
          accountName={accountName}
          waiterEnabled={waiterEnabled}
          waiterId={waiterId}
          setConnected={setConnected}
          setValidated={setValidated}
          testSuite={testSuite}
          setTestSuite={setTestSuite}
        />
      }
      {connected === true && validated === false &&
        <StateCheck 
          accountName={accountName}
          isValidating={isValidating}
          setIsValidating={setIsValidating}
          setValidated={setValidated}
          waiterEnabled={waiterEnabled}
          waiterId={waiterId}
          testSuite={testSuite}
          setTestSuite={setTestSuite}
        />
      }
      {validated === true &&
        <TestCases
          accountName={accountName}
          testSuite={testSuite}
          setTestSuite={setTestSuite}
          isAllRunning={isAllRunning}
          setIsAllRunning={setIsAllRunning}
          waiterEnabled={waiterEnabled}
          waiterId={waiterId}
          setConnected={setConnected}
          setValidated={setValidated}
        />
      }
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <div className="App">
          <HomeComponent />
        </div>
      </Provider>
    </BrowserRouter>
  )
}

export default App;
