import React from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { TEST_STATUS_FAILED, TEST_STATUS_NOT_SUPPORTED, TEST_STATUS_PASSED, TEST_STATUS_UNTESTED } from '../shared/Constants';
import ReactHtmlParser from "react-html-parser";

function TestCaseRow(props) {

  const { i, index, requestor, scenarioType, request, response, result, resultActive, reasons, isSending, successData, testCases, preconditions, showJsonPayload } = props;
  const reasonsList = reasons?.length > 0 ? reasons.map((reason, index) => <li key={index}>{reason}</li>) : "";

  function formatScenarios() {
    if (scenarioType) {
      if (Array.isArray(preconditions) && preconditions.length > 1) {
        if (scenarioType === "SESSIONS") {
          return preconditions.map((precondition, index) =>
            <span key={index}>
              {index !== 0 && <b style={{"marginLeft": "10px"}}>AND</b>}
              <code disabled={result === TEST_STATUS_NOT_SUPPORTED} className="test-case-code no-select">{precondition}</code>
            </span>
          )
        }
        else if (scenarioType === "TABLES") {
          return preconditions.map((precondition, index) =>
            <span key={index}>
              {index !== 0 && <b style={{"marginLeft": "10px"}}>OR</b>}
              <code disabled={result === TEST_STATUS_NOT_SUPPORTED} className="test-case-code no-select">{precondition}</code>
            </span>
          )
        }
      } else {
        return <code disabled={result === TEST_STATUS_NOT_SUPPORTED} className="test-case-code no-select">{preconditions}</code>
      }
    } else {
      return <code disabled={result === TEST_STATUS_NOT_SUPPORTED} className="test-case-code no-select">{preconditions}</code>
    }
  }

  function syntaxHighlight(json, type) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
      var cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          if (type === "request") {
            cls = 'key-request';
          } else {
            cls = 'key-response'
          }
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  }

  return (
    <div className={`test-case ${result === TEST_STATUS_NOT_SUPPORTED ? "disabled test-case-disabled" : ""}`}>
      <div className="test-case-content-container">
        <div className="test-case-number no-select">
          {result === TEST_STATUS_NOT_SUPPORTED && <>Test {index}</>}
          {result === TEST_STATUS_UNTESTED && <>Test {index}</>}
          {result === TEST_STATUS_PASSED && <div className="link" onClick={() => showJsonPayload(testCases, i)}>Test {index}</div>}
          {result === TEST_STATUS_FAILED && <div className="link"  onClick={() => showJsonPayload(testCases, i)}>Test {index}</div>}
        </div>
        <div className="test-case-type no-select">Requestor:<code disabled={result === TEST_STATUS_NOT_SUPPORTED} className="test-case-code">{requestor}</code></div>
        {preconditions && <>Scenario: {formatScenarios()}</>}
        <div className="test-icons-container no-select">
          <div className="test-icon-container ml-5 no-select">
            {isSending && result === TEST_STATUS_UNTESTED &&
              <PuffLoader
                color="#c7254e"
                loading={isSending}
                size={18}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            }
            {result === TEST_STATUS_NOT_SUPPORTED &&
              <div className="test-icon test-case-result test-case-result-not-supported-image no-select"></div>
            }
            {result === TEST_STATUS_PASSED &&
              <div onClick={() => showJsonPayload(testCases, i)} className={`test-icon test-case-result test-case-result-passed-image no-select ${resultActive === true ? "active" : ""}`}></div>
            }
            {result === TEST_STATUS_FAILED &&
              <div onClick={() => showJsonPayload(testCases, i)} className={`test-icon test-case-result test-case-result-failed-image no-select ${resultActive === true ? "active" : ""}`}></div>
            }
          </div>
        </div>

        {result !== TEST_STATUS_UNTESTED && response && resultActive === true &&
          <>
            <div className="test-case-message-container">
              <div className={"test-case-result-message-data-container"}>
                <div className="test-case-message-heading test-case-message-heading-request no-select">Request</div>
                <pre>{ReactHtmlParser(syntaxHighlight(JSON.stringify(request, undefined, 4), "request"))}</pre>
              </div>
              <div className={"test-case-result-message-data-container"}>
                <div className="test-case-message-heading test-case-message-heading-response no-select">Response</div>
                <pre>{ReactHtmlParser(syntaxHighlight(JSON.stringify(response, undefined, 4), "response"))}</pre>
              </div>
            </div>
            <>
              {result === TEST_STATUS_PASSED &&
                <div className={"test-case-result-string test-case-result-passed-string no-select"}>
                  {JSON.stringify(successData).split(",").join(", ")}
                </div>
              }
              {result === TEST_STATUS_FAILED &&
                <div className={"test-case-result-string test-case-result-failed-string no-select"}>
                  <ul>{reasonsList}</ul>
                </div>
              }
            </>
          </>
        }
      </div>
    </div>

  );
}

export { TestCaseRow };
