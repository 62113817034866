import { createRequestor, sendTablesRequest } from "./API";
import { REQUESTOR_TYPE_CARD_MACHINE, TABLE_STATUS_AVAILABLE, TABLE_STATUS_NOT_IN_USE, TABLE_STATUS_OCCUPIED, TABLE_STATUS_PENDING_AVAILABLE, TEST_STATUS_FAILED, TEST_STATUS_PASSED } from "./Constants";

function createFailureResponse(message, response) {
  return `${message} but received a <code>${response.data.error.errorCode}</code> error response with error message: "${response.data.error.errorMessage}"`;
}

async function validateTableExists(accountName, name, waiterId) {
  try {
    const requestBody = {
      requestorInfo: createRequestor(REQUESTOR_TYPE_CARD_MACHINE, waiterId),
      name: name
    }
    const response = await sendTablesRequest(accountName, "getTable", requestBody);

    if (response.data.status === 200) {
      const responseBody = response.data.data;
      return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, successData: `Successfully retrieved the table: '${name}'` };
    } else {
      const responseBody = response.data.error;
      return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: createFailureResponse("Expected to retreive a table response", response) };
    }
  }
  catch(error) {
    console.log(error)
  };
}

async function validateSessionExists(accountName, sessionId, waiterId) {
  try {
    const requestBody = {
      requestorInfo: createRequestor(REQUESTOR_TYPE_CARD_MACHINE, waiterId),
      sessionId: sessionId
    }
    const response = await sendTablesRequest(accountName, "getSession", requestBody);

    if (response.data.status === 200) {
      const responseBody = response.data.data;
      return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, successData: `Successfully retrieved the session: '${sessionId}'` };
    } else {
      const responseBody = response.data.error;
      return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: createFailureResponse("Expected to retreive a session response", response) };
    }
  }
  catch(error) {
    console.log(error)
  };
}

function createListSessionsResponseSummary(payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions, sessionsWithTables, sessionsWithoutTables) {
  return `<ul>
    <li>Payable Session count: ${payableSessions.length}</li>
    <li>Non-payable Session count: ${nonPayableSessions.length}</li>
    <li>Finished Session count: ${finishedSessions.length}</li>
    <li>Unfinished Session count: ${unfinishedSessions.length}</li>
    <li>Sessions with tables count: ${sessionsWithTables.length}</li>
    <li>Sessions without tables count: ${sessionsWithoutTables.length}</li>
  </ul>`;
}

function createListSessionsResponse(result, param, expectedSessions, actualSessions, payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions, sessionsWithTables, sessionsWithoutTables) {
  const summary = createListSessionsResponseSummary(payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions, sessionsWithTables, sessionsWithoutTables);
  const sessionCount = actualSessions.length !== 1 ? "sessions" : "session";
  const paramStr = param && Object.keys(param).length > 0 ? JSON.stringify(param) : "[]";
  return result === TEST_STATUS_PASSED ? `Successfully retrieved and expected ${actualSessions.length} ${sessionCount} matching filters: ${paramStr} where:` + summary : `Expected ${expectedSessions.length} sessions matching filter: ${paramStr} but received ${actualSessions.length} sessions where: ` + summary;
}

async function validateListSessions(accountName, param, initialSessions, waiterId) {
  try {
    const requestBody = {
      requestorInfo: createRequestor(REQUESTOR_TYPE_CARD_MACHINE, waiterId),
    }
    if (param) {
      for (let key of Object.keys(param)) {
        requestBody[key] = param[key]; 
      }
    }
    const response = await sendTablesRequest(accountName, "listSessions", requestBody);

    if (response.data.status === 200) {
      const responseBody = response.data.data;
      const actualSessions = responseBody.sessions;
      let filteredSessions = actualSessions;
      let expectedSessions = initialSessions;
      let payableSessions = actualSessions.filter(session => session.isPayable === true);
      let nonPayableSessions = actualSessions.filter(session => session.isPayable === false);
      let finishedSessions = actualSessions.filter(session => session.finishedAt);
      let unfinishedSessions = actualSessions.filter(session => !session.finishedAt);
      let sessionsWithTables = actualSessions.filter(session => session.tableName);
      let sessionsWithoutTables = actualSessions.filter(session => !session.tableName);

      if (param) {
        if (Object.keys(param).length === 1) {
          for (let key of Object.keys(param)) {
            requestBody[key] = param[key]; 
            switch (key.toString()) {
              case "isPayable":
                if (param[key] === true) {
                  filteredSessions = filteredSessions.filter(session => session.isPayable === true);
                  expectedSessions = initialSessions.filter(session => session.isPayable === true);
                  break;
                }
                if (param[key] === false) {
                  filteredSessions = filteredSessions.filter(session => session.isPayable === false);
                  expectedSessions = initialSessions.filter(session => session.isPayable === false);
                  break;
                }
                break;
              case "isFinished":
                if (param[key] === true) {
                  filteredSessions = filteredSessions.filter(session => session.finishedAt);
                  expectedSessions = initialSessions.filter(session => session.finishedAt);
                  break;
                }
                if (param[key] === false) {
                  filteredSessions = filteredSessions.filter(session => !session.finishedAt);
                  expectedSessions = initialSessions.filter(session => !session.finishedAt);
                  break;
                }
                break;
              case "hasTable":
                if (param[key] === true) {
                  filteredSessions = filteredSessions.filter(session => session.tableName);
                  expectedSessions = initialSessions.filter(session => session.tableName);
                  break;
                }
                if (param[key] === false) { 
                  filteredSessions = filteredSessions.filter(session => !session.tableName);
                  expectedSessions = initialSessions.filter(session => !session.tableName);
                  break;
                }
                break;
              case "tableNames": 
                filteredSessions = filteredSessions.filter(session => session.tableName === param[key][0]);
                expectedSessions = initialSessions.filter(session => session.tableName === param[key][0]);
                break;
              default:
                break;
            }
          }
        } else {
          filteredSessions = filteredSessions.filter(session => session.isPayable === false && !session.finishedAt);
          expectedSessions = initialSessions.filter(session => session.isPayable === false && !session.finishedAt);
        }
      } else {
        expectedSessions = actualSessions;
      }

      if (parseInt(expectedSessions.length) === parseInt(filteredSessions.length)) {
        if (actualSessions.length > 0 && param) {
          if(parseInt(filteredSessions.length) === parseInt(expectedSessions.length) && parseInt(actualSessions.length) === parseInt(expectedSessions.length)) {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: response.data.data, successData: createListSessionsResponse(TEST_STATUS_PASSED, param, expectedSessions, filteredSessions, payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions, sessionsWithTables, sessionsWithoutTables) };
          } else {
            if (filteredSessions.length === 0) {
              const expectedCount = expectedSessions.length !== 1 ? "sessions" : "session";
              const sessionCount = actualSessions.length !== 1 ? "sessions" : "session";
              return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: `Expected only ${expectedSessions.length} ${expectedCount} matching filter "${JSON.stringify(param)}" but received ${actualSessions.length} ${sessionCount} where:` + createListSessionsResponseSummary(payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions, sessionsWithTables, sessionsWithoutTables)  }
            } else return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: createListSessionsResponse(TEST_STATUS_FAILED, param, expectedSessions, filteredSessions, payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions, sessionsWithTables, sessionsWithoutTables) }
          }
        } else if (actualSessions.length === 0) {
          if (param) {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: `Expected at least 1 session matching filter "${JSON.stringify(param)}" but received no sessions at all` };
          } else return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: `Expected at least 1 session but received no sessions at all` };
        } 
        else return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: response.data.data, successData: createListSessionsResponse(TEST_STATUS_PASSED, param, expectedSessions, filteredSessions, payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions, sessionsWithTables, sessionsWithoutTables) };
      } else {
        return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: createListSessionsResponse(TEST_STATUS_FAILED, param, expectedSessions, filteredSessions, payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions, sessionsWithTables, sessionsWithoutTables) };
      }   
    } else {
      const responseBody = response.data.error;
      console.log(response.data)
      return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: createFailureResponse("Expected to retreive a list sessions response", response) };
    }
  }
  catch(error) {
    console.log(error)
  };
}

function createListTablesResponseSummary(availableCount, pendingAvailableCount, occupiedCount, notInUseCount) {
  return `<ul>
    <li><code>${TABLE_STATUS_AVAILABLE}</code> count: ${availableCount}</li>
    <li><code>${TABLE_STATUS_PENDING_AVAILABLE}</code> count: ${pendingAvailableCount}</li>
    <li><code>${TABLE_STATUS_OCCUPIED}</code> count: ${occupiedCount}</li>
    <li><code>${TABLE_STATUS_NOT_IN_USE}</code> count: ${notInUseCount}</li>
  </ul>`;
}

function createListTablesResponse(result, tableStatus, expectedTables, actualTables, availableCount, pendingAvailableCount, occupiedCount, notInUseCount) {
  const summary = createListTablesResponseSummary(availableCount, pendingAvailableCount, occupiedCount, notInUseCount);
  const expectedTableCountGrammar = expectedTables.length !== 1 ? "tables" : "table";
  const tableCountGrammar = actualTables.length !== 1 ? "tables" : "table";
  return result === TEST_STATUS_PASSED ? `Successfully retrieved and expected ${expectedTables.length} ${expectedTableCountGrammar} matching filters: <code>${tableStatus}</code> where:` + summary : `Expected ${expectedTables.length} ${expectedTableCountGrammar} matching filter: <code>${tableStatus}</code> but received ${actualTables.length} ${tableCountGrammar} where: ` + summary;
}

async function validateListTables(accountName, TABLE_STATUS, initialTables, waiterId) {
  try {
    const requestBody = {
      requestorInfo: createRequestor(REQUESTOR_TYPE_CARD_MACHINE, waiterId),
    }
    if (TABLE_STATUS) {
      requestBody.statuses = [ TABLE_STATUS ];
    }
    const response = await sendTablesRequest(accountName, "listTables", requestBody);

    if (response.data.status === 200) {
      const responseBody = response.data.data;
      let actualTables = responseBody.tables;
      let filteredTables = actualTables;
      let expectedTables = initialTables === null ? filteredTables : initialTables;
      const expectedTableCountGrammar = expectedTables.length !== 1 ? "tables" : "table";
      const tableCountGrammar = actualTables.length !== 1 ? "tables" : "table";

      const availableCount = filteredTables.filter(table => table.status === TABLE_STATUS_AVAILABLE)?.length > 0 ? filteredTables.filter(table => table.status === TABLE_STATUS_AVAILABLE).length : 0;
      const pendingAvailableCount = filteredTables.filter(table => table.status === TABLE_STATUS_PENDING_AVAILABLE)?.length > 0 ? filteredTables.filter(table => table.status === TABLE_STATUS_PENDING_AVAILABLE).length : 0;
      const occupiedCount = filteredTables.filter(table => table.status === TABLE_STATUS_OCCUPIED)?.length > 0 ? filteredTables.filter(table => table.status === TABLE_STATUS_OCCUPIED).length : 0;
      const notInUseCount = filteredTables.filter(table => table.status === TABLE_STATUS_NOT_IN_USE)?.length > 0 ? filteredTables.filter(table => table.status === TABLE_STATUS_NOT_IN_USE).length : 0;

      if (TABLE_STATUS) {
        filteredTables = filteredTables.filter(table => table.status === TABLE_STATUS);
        expectedTables = expectedTables.filter(table => table.status === TABLE_STATUS);
      }

      if (parseInt(filteredTables.length) === parseInt(expectedTables.length)) {
        if (filteredTables.length > 0 && TABLE_STATUS) {
          if (parseInt(filteredTables.length) === parseInt(expectedTables.length) && parseInt(actualTables.length) === parseInt(expectedTables.length)) {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: response.data.data, successData: createListTablesResponse(TEST_STATUS_PASSED, TABLE_STATUS, expectedTables, actualTables, availableCount, pendingAvailableCount, occupiedCount, notInUseCount) };
          } else {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: `Expected to only receive ${expectedTables.length} ${expectedTableCountGrammar} each matching table status <code>${TABLE_STATUS}</code> but received ${actualTables.length} ${tableCountGrammar} where: ${createListTablesResponseSummary(availableCount, pendingAvailableCount, occupiedCount, notInUseCount)}` }
          }
        } else if (TABLE_STATUS && filteredTables.length !== 0) {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: `Expected a total of ${expectedTables.length} ${expectedTableCountGrammar} tables each matching filter "${TABLE_STATUS}" but received ${actualTables.length} ${tableCountGrammar} where: ${createListTablesResponseSummary(availableCount, pendingAvailableCount, occupiedCount, notInUseCount)}` };
        } else if (TABLE_STATUS && filteredTables.length === 0) {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: `Expected at least one table matching filter <code>${TABLE_STATUS}</code> but received ${actualTables.length} ${tableCountGrammar} where: ${createListTablesResponseSummary(availableCount, pendingAvailableCount, occupiedCount, notInUseCount)}` };
        } else if (filteredTables.length === 0) {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: `Expected at least one table but received ${actualTables.length} ${tableCountGrammar} where: ${createListTablesResponseSummary(availableCount, pendingAvailableCount, occupiedCount, notInUseCount)}` };
        }
        else return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: response.data.data, successData: createListTablesResponse(TEST_STATUS_PASSED, TABLE_STATUS, expectedTables, actualTables, availableCount, pendingAvailableCount, occupiedCount, notInUseCount) };
      } else {
        return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: `Expected ${expectedTables.length} tables matching filter <code>${TABLE_STATUS}</code> but received ${actualTables.length} ${tableCountGrammar} where: ${createListTablesResponseSummary(availableCount, pendingAvailableCount, occupiedCount, notInUseCount)}` };
      }   
    } else {
      const responseBody = response.data.error;
      return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: createFailureResponse("Expected to retreive a list tables response", response) };
    }
  }
  catch(error) {
    console.log(error)
  };
}

async function validateListBillItems(accountName, SESSION_IDS, initialBillItems, waiterId) {
  try {
    const requestBody = {
      requestorInfo: createRequestor(REQUESTOR_TYPE_CARD_MACHINE, waiterId),
    }
    if (SESSION_IDS) {
      requestBody.sessionIds = SESSION_IDS;
    }
    const response = await sendTablesRequest(accountName, "listBillItems", requestBody);

    if (response.data.status === 200) {
      const responseBody = response.data.data;
      let filteredBillItems = response.data.data.billItems;
      let expectedBillItems = initialBillItems === null ? filteredBillItems : initialBillItems;
      const billItemsGrammar = filteredBillItems.length !== 1 ? "bill items" : "bill item";


      if (SESSION_IDS) {
        filteredBillItems = filteredBillItems.filter(billItems => SESSION_IDS.includes(billItems.sessionId));
        expectedBillItems = expectedBillItems.filter(billItems => SESSION_IDS.includes(billItems.sessionId));
      } else {
        expectedBillItems = expectedBillItems.filter(billItems => billItems.items.length > 0);
        filteredBillItems = filteredBillItems.filter(billItems => billItems.items.length > 0);
      }

      if (parseInt(filteredBillItems.length) === parseInt(expectedBillItems.length)) {
        if (filteredBillItems.length >= 2) { 
          response.data.data.billItems = filteredBillItems;
          return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: response.data.data, successData: `Successfully retrieved ${filteredBillItems.length} billItems` };
        } 
        else if (SESSION_IDS) return { result: TEST_STATUS_FAILED, reason: `Expected at least 2 billItems matching filter <code>${SESSION_IDS}</code> but received ${filteredBillItems.length}` };
        else return {  request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: `Expected at least two non-zero billItems but received ${filteredBillItems.length}` };
      } else {
        return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: `Expected ${expectedBillItems.length} ${billItemsGrammar} but received ${filteredBillItems.length}` };
      }   
    } else {
      const responseBody = response.data.error;
      return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reason: createFailureResponse("Expected to retreive a list bill items response", response) };
    }
  }
  catch(error) {
    console.log(error)
  };
}

export { validateTableExists, validateSessionExists, validateListSessions, validateListTables, validateListBillItems };