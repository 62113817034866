import React from 'react';
import { createRequestor, sendTablesRequest } from '../shared/API';
import { REQUESTOR_TYPE_CARD_MACHINE } from '../shared/Constants';
import PuffLoader from 'react-spinners/PuffLoader';

function Login(props) {

  const { accountName, setAccountName, connected, setConnected, isConnecting, setIsConnecting, waiterEnabled, setWaiterEnabled, waiterId, setWaiterId, notification, setNotification } = props;

  function toggleWaiterId() {
    setWaiterEnabled(!waiterEnabled);
  }

  function updateAccountName(e) {
    setAccountName(e.target.value);
  }

  function updateWaiterId(e) {
    setWaiterId(e.target.value);
  }

  function clearError() {
    setNotification({ visible: false, message: "" });
  }

  async function attemptPOSConnection() {
    setIsConnecting(true)
    clearError()
    const response = waiterEnabled === true ?
      await sendTablesRequest(accountName, "listSessions", { requestorInfo: createRequestor(REQUESTOR_TYPE_CARD_MACHINE, waiterId) }) :
      await sendTablesRequest(accountName, "listSessions", { requestorInfo: createRequestor(REQUESTOR_TYPE_CARD_MACHINE) })
    if (response.data.error?.errorMessage) {
      setNotification({ visible: true, message: response.data.error.errorMessage });
    } else if (response.data.error) {
      setNotification({ visible: true, message: response.data.error });
    } else {
      setConnected(true);
    }
    setIsConnecting(false)
  }

  return (
    <>
      {connected === false &&
        <div style={{ "width": "100%", "margin": "0 auto" }}>
          <div style={{ "width": "100%", "minHeight": "100vh", "display": "flex", "alignItems": "center", "justifyContent": "center" }}>
            <div style={{ "width": "390px", "background": "#fff", "borderRadius": "10px", "position": "relative", "padding": "50px 0 90px 0" }}>
              <span style={{ "fontSize": "30px", "lineHeight": "1.2", "textTransform": "uppercase", "textAlign": "center", "width": "100%", "display": "block", "paddingBottom": "51px" }}>
                Connect to EPOS
              </span>
              {isConnecting === true && waiterEnabled === false &&
                <div style={{ "margin": "0 auto 38px auto", "width": "135px" }}>
                  <PuffLoader
                    color="#2196F3"
                    size={135}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              }
              {isConnecting === true && waiterEnabled === true &&
                <div style={{ "margin": "0 auto 38px auto", "width": "200px" }}>
                  <PuffLoader
                    color="#2196F3"
                    size={200}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              }
              {isConnecting === false &&
                <>
                  <div className="wrap-input100" data-validate="Account name is required">
                    <input className="input100" type="text" name="accountName" placeholder="Account Name" defaultValue={accountName} onKeyUp={updateAccountName} onFocus={clearError} />
                    <span className="focus-input100" />
                  </div>
                  <div className="input100" style={{ "marginTop": "30px", "textAlign": "left", "color": "grey" }}>
                    Toggle Waiter Id
                    <label style={{ "position": "relative", "width": "60px", "height": "33px", "top": "-2px", "margin": "0 5px", "float": "right" }}>
                      <input type="checkbox" onClick={() => toggleWaiterId()} defaultChecked={waiterEnabled} />
                      <span className="slider2"></span>
                    </label>
                  </div>
                  {waiterEnabled === true &&
                    <div className="wrap-input100" data-validate="Waiter Id is required">
                      <input className="input100" type="number" name="waiterId" placeholder="Waiter Id" defaultValue={waiterId} onKeyUp={updateWaiterId} onChange={updateWaiterId} onFocus={clearError} />
                      <span className="focus-input100" />
                    </div>
                  }
                </>
              }
              <div>
                <button disabled={isConnecting === true || accountName.length === 0 || (waiterEnabled === true && waiterId.length === 0)} onClick={() => attemptPOSConnection()} className="login100-btn">Connect</button>
              </div>
              <div style={{ "width": "100%", "height": "100px" }}>
                <div id="notification" className={notification.visible === true ? "animate" : ""}>
                  {notification.message}
                </div>
              </div>
            </div>
          </div >
        </div >
      }
    </>
  );
}

export { Login };