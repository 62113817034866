import React, { useState } from 'react';
import { TestCaseHeader } from '../shared/TestCaseHeader';
import { TestCaseRow } from '../shared/TestCaseRow';
import { TABLE_STATUS_AVAILABLE, TABLE_STATUS_NOT_IN_USE, TABLE_STATUS_OCCUPIED, TABLE_STATUS_PENDING_AVAILABLE, TEST_PRE_CONDITION_TABLE_NO_PARAMS } from '../shared/Constants';

function ListTables(props) {
  const { testSuite, setTestSuite, runTests, showJsonPayload, filterTestResultsByType } = props;
  const [isSending, setIsSending] = useState(false)

  const testCase = testSuite.listTables;
  const testCases = testCase.filteredTestCases ? testCase.filteredTestCases : testCase.testCases;
  const headerVisible = testCase.headerVisible;

  function switchTableStatusTag(tableStatus) {
    switch (tableStatus) {
      case TABLE_STATUS_AVAILABLE: return "AVAILABLE_TABLES";
      case TABLE_STATUS_PENDING_AVAILABLE: return "PENDING_AVAILABLE_TABLES";
      case TABLE_STATUS_OCCUPIED: return "OCCUPIED_TABLES";
      case TABLE_STATUS_NOT_IN_USE: return "NOT_IN_USE_TABLES";
      default: break;
    }
  }

  const testCasesDiv = testCases.map((test, index) => {
    let preconditions = [];
    if (test.statuses.length >= 1) {
      for (let i = 0; i < test.statuses.length; i++) {
        preconditions.push(switchTableStatusTag(test.statuses[i]));
      }
    } else {
      preconditions = TEST_PRE_CONDITION_TABLE_NO_PARAMS;
    }

    return (
      <TestCaseRow 
        i={index}
        key={index}
        index={test.index}
        scenarioType="TABLES"
        preconditions={preconditions}
        testCases={testCases}
        requestor={test.requestor}
        request={test.request}
        response={test.response}
        result={test.result}
        requestActive={test.requestActive}
        responseActive={test.responseActive}
        resultActive={test.resultActive}
        reasons={test.reasons}
        isSending={isSending}
        showJsonPayload={showJsonPayload}
        successData={test.successData}
      />
    )
  })

  function setHeaderVisible () {
    testCase.headerVisible = !headerVisible;
    setTestSuite({...testSuite});
  }
  
  return (
    <div className="test-case-container">
      <TestCaseHeader
        testCase={testCase}
        setHeaderVisible={setHeaderVisible}
        isSending={isSending}
        setIsSending={setIsSending}
        runTests={runTests}
        filterPassed={testCase.filterPassed}
        filterFailed={testCase.filterFailed}
        filterNotSupported={testCase.filterNotSupported}
        filterTestResultsByType={filterTestResultsByType}
      />
      { headerVisible === true &&
        <div className='test-case-description'>
          <div className="test-case-header-details">
            <div className="pre-tests">
              <hr/>
              {testCasesDiv}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export { ListTables };