import axios from "axios";
import { SERVER_HOST } from "../../config";
import { v4 as uuidv4 } from 'uuid';
import { EPOS_ERROR_SESSION_ALREADY_LOCKED, EPOS_ERROR_SESSION_NOT_LOCKED, EPOS_ERROR_SESSION_NO_SUCH_SESSION, REQUESTOR_TYPE_CARD_MACHINE, REQUESTOR_TYPE_CONSUMER_DEVICE, TABLE_STATUS_AVAILABLE, TABLE_STATUS_NOT_IN_USE, TABLE_STATUS_OCCUPIED, TABLE_STATUS_PENDING_AVAILABLE, TEST_LIST_BILL_ITEMS_TEST_FIRST_SESSION_WITH_BILL, TEST_LIST_BILL_ITEMS_TEST_ONE_NON_EXISTING_SESSION, TEST_LIST_BILL_ITEMS_TEST_ONE_SESSION_WITH_BILL_WITH_ONE_NON_EXISTING_SESSION, TEST_LIST_BILL_ITEMS_TEST_SECOND_SESSION_WITH_BILL, TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL, TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL_WITH_ONE_NON_EXISTING_SESSIONS, TEST_PRE_CONDITION_SESSION_FINISHED, TEST_PRE_CONDITION_SESSION_LOCKED, TEST_PRE_CONDITION_SESSION_NON_EXISTING, TEST_PRE_CONDITION_SESSION_UNLOCKED, TEST_STATUS_FAILED, TEST_STATUS_PASSED, TEST_PRE_CONDITION_TABLE_NON_EXISTING, EPOS_ERROR_TABLE_NO_SUCH_TABLE, TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL, TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_GRATUITY, TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_CASHBACK, TEST_PRE_CONDITION_PAYMENT_DUPLICATE, TEST_PRE_CONDITION_PAYMENT_CP_CANCELLED, TEST_PRE_CONDITION_PAYMENT_CP_DECLINED, TEST_PRE_CONDITION_PAYMENT_CP_UNKNOWN, TEST_PRE_CONDITION_PAYMENT_RP_CANCELLED, TEST_PRE_CONDITION_PAYMENT_RP_UNKNOWN, TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL, TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_GRATUITY, TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_CASHBACK, EPOS_ERROR_PAYMENT_ALREADY_RECORDED, EPOS_ERROR_PAYMENT_NOT_RECORDED, TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITH_ITEMS, TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITHOUT_ITEMS, TEST_LIST_BILL_ITEMS_TEST_NO_PARAMS } from "./Constants";

axios.defaults.baseURL = SERVER_HOST;

function createRequestor(requestor, waiterId) {
  return requestor === REQUESTOR_TYPE_CARD_MACHINE ? setCardMachineRequestorType(waiterId) : consumerDeviceRequestorType();
}

function setSuccessfulPaymentObjectWithPaymentId(requestor, paymentId, sessionId, baseAmount, isoDateString) {
  return createPaymentObject(requestor, paymentId, sessionId, baseAmount, undefined, undefined, true, undefined, isoDateString);
}

function setSuccessfulPaymentObject(requestor, sessionId, baseAmount) {
  return createPaymentObject(requestor, undefined, sessionId, baseAmount, undefined, undefined, true, undefined, undefined);
}

function setPaymentObjectWithBaseAmount(requestor, sessionId, baseAmount, paymentSuccessful, paymentStatus) {
  return createPaymentObject(requestor, undefined, sessionId, baseAmount, undefined, undefined, paymentSuccessful, paymentStatus, undefined);
}

function setSuccessfulPaymentObjectWithAmounts(requestor, sessionId, baseAmount, gratuityAmount, cashbackAmount) {
  return createPaymentObject(requestor, undefined, sessionId, baseAmount, gratuityAmount, cashbackAmount, true, undefined, undefined);
}

function createPaymentObject(requestor, paymentId, sessionId, baseAmount, gratuityAmount, cashbackAmount, paymentSuccessful, paymentStatus, isoDateString) {
  let date = new Date();
  let dateIsoString = isoDateString ? isoDateString : date.toISOString();
  return {
    id: paymentId !== undefined ? paymentId : uuidv4(),
    sessionId: sessionId,
    currency: "GBP",
    baseAmount: baseAmount,
    gratuityAmount: gratuityAmount !== undefined ? gratuityAmount : 0,
    cashbackAmount: cashbackAmount !== undefined ? cashbackAmount : 0,
    paymentSuccessful: paymentSuccessful,
    methodDetails: requestor === REQUESTOR_TYPE_CARD_MACHINE ? setCardPresentMethodDetails(paymentStatus) : setRemotePaymentMethodDetails(paymentStatus),
    attemptedAt: dateIsoString
  }
}

function defaultCardDetails() {
  return {
    scheme: "CARD_SCHEME_AMEX",
    last4PAN: "1234",
    expiryDate: {
      month: 12,
      year: 2032
    },
    fundingType: "CARD_FUNDING_TYPE_CREDIT"
  }
}

function setCardPresentMethodDetails(paymentStatus) {
  return {
    method: "PAYMENT_METHOD_CARD_PRESENT",
    cardPresentPaymentInfo: {
      authCode: "ABC123",
      entryMode: "ENTRY_MODE_CONTACTLESS",
      card: defaultCardDetails(),
      cardholderVerificationMethod: "CARDHOLDER_VERIFICATION_METHOD_PIN",
      terminalId: "87654321",
      merchantId: "12341234",
      acquirerTransactionId: "01234567-0123-0123-0123-0123456789ab"
    },
    cardPresentPaymentStatus: paymentStatus !== undefined ? paymentStatus : TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL
  }
}

function setRemotePaymentMethodDetails(paymentStatus) {
  return {
    method: "PAYMENT_METHOD_REMOTE",
    remotePaymentInfo: {
      authCode: "ABC123",
      card: defaultCardDetails(),
      remoteVerificationMethod: "REMOTE_VERIFICATION_METHOD_3DS2",
      merchantId: "12341234",
      acquirerTransactionId: "01234567-0123-0123-0123-0123456789ab"
    },
    remotePaymentStatus: paymentStatus !== undefined ? paymentStatus : TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL
  }
}

function setCardMachineRequestorType(waiterId) {
  return waiterId ? {
    requestorType: REQUESTOR_TYPE_CARD_MACHINE,
    cardMachineRequestorInfo: {
      terminalId: "87654321",
      waiterId: waiterId
    }
  } : {
    requestorType: REQUESTOR_TYPE_CARD_MACHINE,
    cardMachineRequestorInfo: {
      terminalId: "87654321"
    }
  };
}

function consumerDeviceRequestorType() {
  return {
    requestorType: REQUESTOR_TYPE_CONSUMER_DEVICE,
    consumerDeviceRequestorInfo: {}
  }
}

// Used for testing
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function testGetTable(accountName, requestor, precondition, name, waiterId) {
  try {
    const requestBody = {
      requestorInfo: createRequestor(requestor, waiterId),
      name: name
    }
    const response = await sendTablesRequest(accountName, "getTable", requestBody);
    let responseBody;

    let reasons = [];

    if (response.data.status === 200) {
      responseBody = response.data.data;
      switch (precondition) {
        case TEST_PRE_CONDITION_TABLE_NON_EXISTING:
          reasons.push(`Expected a '${EPOS_ERROR_TABLE_NO_SUCH_TABLE}' error response but received a table`);
          break;
        case TABLE_STATUS_AVAILABLE:
        case TABLE_STATUS_PENDING_AVAILABLE:
        case TABLE_STATUS_OCCUPIED:
        case TABLE_STATUS_NOT_IN_USE:
          if (responseBody.table.status !== precondition) {
            reasons.push(`Expected a table response with a '${precondition}' status but received a table response with a '${responseBody.table.status}' status`);
          }
          if (responseBody.table.name !== name) {
            reasons.push(`Expected a table response with a '${name}' name but received a table response with a '${responseBody.table.name}' name`);
          }
          break;
        default: break;
      }
    } else {
      responseBody = response.data.error;
      switch (precondition) {
        case TEST_PRE_CONDITION_TABLE_NON_EXISTING:
          return responseBody.errorCode === EPOS_ERROR_TABLE_NO_SUCH_TABLE ? { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `${createResponse(true, response)}` } : { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, data: `${createResponse(false, response, `to receive a ${EPOS_ERROR_TABLE_NO_SUCH_TABLE} error`)}` };
        case TABLE_STATUS_AVAILABLE:
        case TABLE_STATUS_PENDING_AVAILABLE:
        case TABLE_STATUS_OCCUPIED:
        case TABLE_STATUS_NOT_IN_USE:
          reasons.push(`${createResponse(false, response, "to receieve a table")}`);
          break;
        default: break;
      }
    }

    return reasons.length === 0 ? { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `Successfully validated the table response: ${JSON.stringify(response.data.data.table)}` } : { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: reasons };
  }
  catch (error) {
    console.log(error)
  };
}

async function testGetSession(accountName, requestor, precondition, sessionId, waiterId) {
  try {
    const requestBody = {
      requestorInfo: createRequestor(requestor, waiterId),
      sessionId: sessionId
    }
    const response = await sendTablesRequest(accountName, "getSession", requestBody);

    let reasons = [];
    let responseBody;

    if (response.data.status === 200) {
      responseBody = response.data.data;
      switch (precondition) {
        case TEST_PRE_CONDITION_SESSION_NON_EXISTING:
          reasons.push(`Expected a '${EPOS_ERROR_SESSION_NO_SUCH_SESSION}' error response but received session`);
          break;
        case TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITH_ITEMS:
          if (!responseBody.session.createdAt) {
            reasons.push("Expected a valid value to be set for the 'createdAt' timestamp for a new session");
          }
          if (responseBody.session.isPayable === false) {
            reasons.push("Expected 'isPayable' to be 'true' for a new session with items");
          }
          if (responseBody.session.finishedAt) {
            reasons.push("Expected no value to be set for the 'finishedAt' timestamp for a new session");
          }
          break;  
        case TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITHOUT_ITEMS:
            if (!responseBody.session.createdAt) {
              reasons.push("Expected a valid value to be set for the 'createdAt' timestamp for a new session");
            }
            if (responseBody.session.isPayable === true) {
              reasons.push("Expected 'isPayable' to be 'false' for a new session without items");
            }
            if (responseBody.session.finishedAt) {
              reasons.push("Expected no value to be set for the 'finishedAt' timestamp for a new session");
            }
            break;
        case TEST_PRE_CONDITION_SESSION_FINISHED:
          if (!responseBody.session.createdAt) {
            reasons.push("Expected a valid value to be set for the 'createdAt' timestamp for a finished session");
          }
          if (responseBody.session.isPayable === true) {
            reasons.push("Expected 'isPayable' to be 'false' for a completed session");
          }
          if (!responseBody.session.finishedAt) {
            reasons.push("Expected a valid value to be set for the 'finishedAt' timestamp for a completed session");
          }
          break;
        default: break;
      }
    } else {
      responseBody = response.data.error;
      switch (precondition) {
        case TEST_PRE_CONDITION_SESSION_NON_EXISTING:
          return responseBody.errorCode === EPOS_ERROR_SESSION_NO_SUCH_SESSION ? { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `${createResponse(true, response)}` } : { result: TEST_STATUS_FAILED, data: `${createResponse(false, response, `to receive a ${EPOS_ERROR_SESSION_NO_SUCH_SESSION} error`)}` };
        case TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITH_ITEMS:
        case TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITHOUT_ITEMS:
        case TEST_PRE_CONDITION_SESSION_FINISHED:
          reasons.push(`${createResponse(false, response, "to receieve a session")}`);
          break;
        default: break;
      }
    }
    return reasons.length === 0 ? { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `Successfully validated the session response: ${JSON.stringify(responseBody.session)}` } : { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: reasons };
  }
  catch (error) {
    console.log(error)
  };
}

async function testListTableRequest(accountName, requestor, statuses, totalTables, availableTables, pendingTables, occupiedTables, notInUseTables, waiterId) {
  try {
    const requestBody = {
      requestorInfo: createRequestor(requestor, waiterId),
    }
    if (statuses.length > 0) {
      requestBody.statuses = statuses;
    }
    const response = await sendTablesRequest(accountName, "listTables", requestBody);
    let responseBody;

    if (response.data.status === 200) {
      responseBody = response.data.data;
      const actualTables = responseBody.tables;
      const actualTableNames = responseBody.tables.map(table => table.name);
      if (statuses.length === 0) {
        if (actualTables.length === parseInt(totalTables)) {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED };
        } else {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected tables: ${totalTables} but received: ${actualTables.length}`] };
        }
      } else {
        let failed = false;
        let reasons = [];
        let count = 0;
        statuses.forEach(status => {
          switch (status) {
            case TABLE_STATUS_AVAILABLE: count = count + parseInt(availableTables); break;
            case TABLE_STATUS_PENDING_AVAILABLE: count = count + parseInt(pendingTables); break;
            case TABLE_STATUS_OCCUPIED: count = count + parseInt(occupiedTables); break;
            case TABLE_STATUS_NOT_IN_USE: count = count + parseInt(notInUseTables); break;
            default: break;
          }
        });
        if (actualTables.length !== count) {
          failed = true;
          reasons.push(`Expected tables: ${count} but received: ${actualTables.length}`)
        }

        const allowedTableStatuses = actualTables.filter(table => statuses.includes(table.status)).length;
        const tableStatusViolations = actualTables.length - allowedTableStatuses;
        if (tableStatusViolations > 0) {
          failed = true;
          reasons.push(`Found ${tableStatusViolations} ${tableStatusViolations === 1 ? "table" : "tables"} that violates the allowed statuses: [${statuses.toString()}]`);
        }

        if (failed) {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: reasons };
        } else {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: actualTableNames };
        }
      }
    } else {
      responseBody = response.data.error;
      return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, "to receieve a list of tables")}`] };
    }
  }
  catch (error) {
    console.log(error)
  };
}

async function testListSessionsRequest(
  accountName, requestor, params, totalSessions, payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions,
  sessionsWithTable, sessionsWithoutTable, sessionWithTableName, waiterId) {
  try {
    const requestBody = {
      requestorInfo: createRequestor(requestor, waiterId),
    }
    let expectedSessions = totalSessions;
    if (params) {
      for (let i = 0; i < params.length; i++) {
        for (let key of Object.keys(params[i])) {
          requestBody[key] = params[i][key];
          switch (key.toString()) {
            case "isPayable":
              if (params[i][key] === true) {
                expectedSessions = expectedSessions.filter(session => payableSessions && payableSessions.indexOf(session) !== -1);
                break;
              }
              if (params[i][key] === false) {
                expectedSessions = expectedSessions.filter(session => nonPayableSessions && nonPayableSessions.indexOf(session) !== -1);
                break;
              }
              break;
            case "isFinished":
              if (params[i][key] === true) {
                expectedSessions = expectedSessions.filter(session => finishedSessions && finishedSessions.indexOf(session) !== -1);
                break;
              }
              if (params[i][key] === false) {
                expectedSessions = expectedSessions.filter(session => unfinishedSessions && unfinishedSessions.indexOf(session) !== -1);
                break;
              }
              break;
            case "hasTable":
              if (params[i][key] === true) {
                expectedSessions = expectedSessions.filter(session => sessionsWithTable && sessionsWithTable.indexOf(session) !== -1);
                break;
              }
              if (params[i][key] === false) {
                expectedSessions = expectedSessions.filter(session => sessionsWithoutTable && sessionsWithoutTable.indexOf(session) !== -1);
                break;
              }
              break;
            case "tableNames": expectedSessions = expectedSessions.filter(session => sessionsWithTable && sessionWithTableName.indexOf(session) !== -1);
              break;
            default: console.log("Something is not working here!")
              break;
          }
        }
      }
    }
    const response = await sendTablesRequest(accountName, "listSessions", requestBody);
    let responseBody;

    if (response.data.status === 200) {
      responseBody = response.data.data;
      const actualSessions = responseBody.sessions.map(session => session.id);
      if (actualSessions.length === 0) {
        if (actualSessions.length === expectedSessions.length) {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: actualSessions };
        } else {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected sessions: ${JSON.stringify(expectedSessions)} but received none`] };
        }
      }

      let failed = false;
      let reasons = [];

      if (actualSessions.length === expectedSessions.length && actualSessions.every((session, i) => session === expectedSessions[i])) {
        return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: actualSessions };
      } else {
        failed = true;
        reasons.push(`Expected sessions (${expectedSessions.length}): ${JSON.stringify(expectedSessions)} but received sessions (${actualSessions.length}): ${JSON.stringify(actualSessions)}`)
      }

      if (failed) {
        return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: reasons }
      }

    } else {
      responseBody = response.data.error;
      return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, "to receieve a list of sessions")}`] };
    }
  }
  catch (error) {
    console.log(error)
  };
}

async function testListBillItemsRequest(
  accountName, requestor, sessionIds, precondition, twoValidSessionIds, totalBillItems, waiterId) {
  try {
    const requestBody = {
      requestorInfo: createRequestor(requestor, waiterId),
    }
    if (sessionIds.length > 0) {
      requestBody.sessionIds = sessionIds;
    }
    
    let expectedBillItems = totalBillItems;
    
    const response = await sendTablesRequest(accountName, "listBillItems", requestBody);
    let responseBody;

    if (response.data.status === 200) {
      responseBody = response.data.data;
      switch (precondition) {
        case TEST_LIST_BILL_ITEMS_TEST_NO_PARAMS:
          expectedBillItems = totalBillItems;
          break;
        case TEST_LIST_BILL_ITEMS_TEST_ONE_NON_EXISTING_SESSION:
          expectedBillItems = [];
          break;
        case TEST_LIST_BILL_ITEMS_TEST_FIRST_SESSION_WITH_BILL:
        case TEST_LIST_BILL_ITEMS_TEST_ONE_SESSION_WITH_BILL_WITH_ONE_NON_EXISTING_SESSION:
          expectedBillItems = [twoValidSessionIds[0]];
          break;
        case TEST_LIST_BILL_ITEMS_TEST_SECOND_SESSION_WITH_BILL:
          expectedBillItems = [twoValidSessionIds[1]];
          break;
        case TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL:
        case TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL_WITH_ONE_NON_EXISTING_SESSIONS:
          expectedBillItems = twoValidSessionIds;
          break;
        default: break;
      }

      let actualBillItems = responseBody.billItems.map(billItem => billItem.sessionId);
      if (actualBillItems.length === 0) {
        if (actualBillItems.length === expectedBillItems.length) {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: actualBillItems };
        } else {
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected billItems: ${JSON.stringify(expectedBillItems)} but received: []`] };
        }
      }

      let failed = false;
      let reasons = [];

      if (precondition === TEST_LIST_BILL_ITEMS_TEST_NO_PARAMS) {
        actualBillItems = responseBody.billItems.filter(billItem => billItem.items.length > 0).map(billItem => billItem.sessionId);
      }

      if (actualBillItems.length === expectedBillItems.length && actualBillItems.every((session, i) => session === expectedBillItems[i])) {
        return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: actualBillItems };
      } else {
        failed = true;
        reasons.push(`Expected billItems (${expectedBillItems.length}): ${JSON.stringify(expectedBillItems)} but received billItems (${actualBillItems.length}): ${JSON.stringify(actualBillItems)}`)
      }

      if (failed) {
        return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: reasons }
      }

    } else {
      responseBody = response.data.error;
      return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, "to receieve a list of bill items")}`] };
    }
  }
  catch (error) {
    console.log(error)
  };
}

async function testLockSessionRequest(accountName, requestor, sessionId, precondition, waiterId) {
  try {
    switch (precondition) {
      case TEST_PRE_CONDITION_SESSION_LOCKED:
        await testLockSessionRequest(accountName, requestor, sessionId, undefined, waiterId);
        break;
      case TEST_PRE_CONDITION_SESSION_UNLOCKED:
        await testUnlockSessionRequest(accountName, requestor, sessionId, undefined, waiterId);
        break;
      default:
        break;
    }
    const requestBody = {
      requestorInfo: createRequestor(requestor, waiterId),
      sessionId: sessionId
    }
    const response = await sendTablesRequest(accountName, "lockSession", requestBody);
    let responseBody;
    if (response.data.status === 200) {
      responseBody = response.data.data;
      switch (precondition) {
        case TEST_PRE_CONDITION_SESSION_NON_EXISTING:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected a '${EPOS_ERROR_SESSION_NO_SUCH_SESSION}' error response but received session`] };
        case TEST_PRE_CONDITION_SESSION_LOCKED:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected a '${EPOS_ERROR_SESSION_ALREADY_LOCKED}' error response but received session`] };
        case TEST_PRE_CONDITION_SESSION_UNLOCKED:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: "Successfully locked the session" };
        default:
          return { result: "PRE_CONDITION" }
      }
    } else {
      responseBody = response.data.error;
      switch (precondition) {
        case TEST_PRE_CONDITION_SESSION_NON_EXISTING:
          return response.data.error.errorCode === EPOS_ERROR_SESSION_NO_SUCH_SESSION ?
            { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `${createResponse(true, response)}` } :
            { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, `a '${EPOS_ERROR_SESSION_NO_SUCH_SESSION}' error response`)}'`] };
        case TEST_PRE_CONDITION_SESSION_LOCKED:
          return response.data.error.errorCode === EPOS_ERROR_SESSION_ALREADY_LOCKED ?
            { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `${createResponse(true, response)}` } :
            { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, `a '${EPOS_ERROR_SESSION_ALREADY_LOCKED}' error response`)}`] };
        case TEST_PRE_CONDITION_SESSION_UNLOCKED:
          return {  request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, "to successfully lock the session")}`] };
        default:
          return { result: "PRE_CONDITION" }
      }
    }
  }
  catch (error) {
    console.log(error)
  };
}

async function testRecordPaymentRequest(accountName, requestor, sessionId, duplicatePaymentId, duplicateAttemptedIsoString, preconditions, waiterId) {
  try {
    switch (preconditions[0]) {
      case TEST_PRE_CONDITION_SESSION_UNLOCKED:
        await testUnlockSessionRequest(accountName, requestor, sessionId, undefined, waiterId);
        break;
      default:
        await testLockSessionRequest(accountName, requestor, sessionId, undefined, waiterId);
        break;
    }

    let payment;

    const initialBillItems = await sendTablesRequest(accountName, "getBillItems", {
      requestorInfo: createRequestor(requestor, waiterId),
      sessionId: sessionId
    });

    const paidAmountBeforePayment = initialBillItems?.data?.status === 200 ? initialBillItems.data.data.billItems.paidAmount : 0;
    const totalAmountBeforePayment = initialBillItems?.data?.status === 200 ? initialBillItems.data.data.billItems.totalAmount : 0;
    let expectedRemainderAmountBeforePayment = totalAmountBeforePayment - paidAmountBeforePayment;

    switch (preconditions[0]) {
      case TEST_PRE_CONDITION_SESSION_NON_EXISTING:
      case TEST_PRE_CONDITION_SESSION_UNLOCKED:
        payment = setSuccessfulPaymentObject(requestor, sessionId, 1);
        break;
      case TEST_PRE_CONDITION_PAYMENT_DUPLICATE:
        payment = setSuccessfulPaymentObjectWithPaymentId(requestor, duplicatePaymentId, sessionId, 1, duplicateAttemptedIsoString)
        break;
      case TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL:
      case TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL:
        payment = setSuccessfulPaymentObject(requestor, sessionId, 1);
        expectedRemainderAmountBeforePayment -=1;
        break;
      case TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_GRATUITY:
      case TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_GRATUITY:
        payment = setSuccessfulPaymentObjectWithAmounts(requestor, sessionId, 1, 1, 0);
        expectedRemainderAmountBeforePayment -=1;
        break;
      case TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_CASHBACK:
      case TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_CASHBACK:
        payment = setSuccessfulPaymentObjectWithAmounts(requestor, sessionId, 1, 0, 1);
        expectedRemainderAmountBeforePayment -=1;
        break;
      case TEST_PRE_CONDITION_PAYMENT_CP_CANCELLED:
      case TEST_PRE_CONDITION_PAYMENT_CP_DECLINED:
      case TEST_PRE_CONDITION_PAYMENT_CP_UNKNOWN:
      case TEST_PRE_CONDITION_PAYMENT_RP_CANCELLED:
      case TEST_PRE_CONDITION_PAYMENT_RP_UNKNOWN:
        payment = setPaymentObjectWithBaseAmount(requestor, sessionId, 1, false, preconditions[0]);
        break;
      default: break;
    }

    const requestBody = {
      requestorInfo: createRequestor(requestor, waiterId),
      payment: payment
    };
    const response = await sendTablesRequest(accountName, "recordPayment", requestBody);
    let responseBody;

    if (response.data.status === 200) {
      responseBody = response.data.data;
      switch (preconditions[0]) {
        case TEST_PRE_CONDITION_SESSION_NON_EXISTING:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected a '${EPOS_ERROR_SESSION_NO_SUCH_SESSION}' error response but received a payment`] };
        case TEST_PRE_CONDITION_SESSION_UNLOCKED:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected a '${EPOS_ERROR_SESSION_NOT_LOCKED}' error response but received a payment`] };
        case TEST_PRE_CONDITION_PAYMENT_DUPLICATE:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected a '${EPOS_ERROR_PAYMENT_ALREADY_RECORDED}' error response but received a payment`] };
        case TEST_PRE_CONDITION_PAYMENT_CP_CANCELLED:
        case TEST_PRE_CONDITION_PAYMENT_CP_DECLINED:
        case TEST_PRE_CONDITION_PAYMENT_CP_UNKNOWN:
        case TEST_PRE_CONDITION_PAYMENT_RP_CANCELLED:
        case TEST_PRE_CONDITION_PAYMENT_RP_UNKNOWN:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected a '${EPOS_ERROR_PAYMENT_NOT_RECORDED}' error response but received a payment`] };
        case TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL:
        case TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL:
        case TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_GRATUITY:
        case TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_GRATUITY:
        case TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_CASHBACK:
        case TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_CASHBACK:
          const updatedBillItems = await sendTablesRequest(accountName, "getBillItems", {
            requestorInfo: createRequestor(requestor, waiterId),
            sessionId: sessionId
          });

          const paidAmountAfterPayment = updatedBillItems?.data?.status === 200 ? updatedBillItems.data.data.billItems.paidAmount : 0;
          const totalAmounAfterPayment = updatedBillItems?.data?.status === 200 ? updatedBillItems.data.data.billItems.totalAmount : 0;
          let remainderAmountAfterPayment = totalAmounAfterPayment - paidAmountAfterPayment;

          if (expectedRemainderAmountBeforePayment !== remainderAmountAfterPayment) {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected the outstanding balance for the payment to be ${expectedRemainderAmountBeforePayment} but it is ${remainderAmountAfterPayment}. The initial 'totalAmount' and 'paidAmount' values before the payment were ${totalAmountBeforePayment} and ${paidAmountBeforePayment} and post payment are now ${totalAmounAfterPayment} and ${paidAmountAfterPayment}.`] };
          } else {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `Successfully recorded the payment on the POS. The initial 'totalAmount' and 'paidAmount' values before the payment were ${totalAmountBeforePayment} and ${paidAmountBeforePayment} and post payment are now ${totalAmounAfterPayment} and ${paidAmountAfterPayment}` }
          }
        default: break;
      }
    } else {
      responseBody = response.data.error;
      switch (preconditions[0]) {
        case TEST_PRE_CONDITION_SESSION_NON_EXISTING:
          if (response.data.error.errorCode === EPOS_ERROR_SESSION_NO_SUCH_SESSION) {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `${createResponse(true, response)}` };
          } else {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, `a '${EPOS_ERROR_SESSION_NO_SUCH_SESSION}' error response`)}`] }
          }
        case TEST_PRE_CONDITION_SESSION_UNLOCKED:
          if (response.data.error.errorCode === EPOS_ERROR_SESSION_NOT_LOCKED) {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `${createResponse(true, response)}` };
          } else {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, `a '${EPOS_ERROR_SESSION_NOT_LOCKED}' error response`)}`] }
          }
        case TEST_PRE_CONDITION_PAYMENT_DUPLICATE:
          if (response.data.error.errorCode === EPOS_ERROR_PAYMENT_ALREADY_RECORDED) {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `${createResponse(true, response)}` };
          } else {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, `a '${EPOS_ERROR_PAYMENT_ALREADY_RECORDED}' error response`)}`] }
          }
        case TEST_PRE_CONDITION_PAYMENT_CP_CANCELLED:
        case TEST_PRE_CONDITION_PAYMENT_CP_DECLINED:
        case TEST_PRE_CONDITION_PAYMENT_CP_UNKNOWN:
        case TEST_PRE_CONDITION_PAYMENT_RP_CANCELLED:
        case TEST_PRE_CONDITION_PAYMENT_RP_UNKNOWN:
          if (responseBody.errorCode === EPOS_ERROR_PAYMENT_NOT_RECORDED) {
            const updatedBillItems = await sendTablesRequest(accountName, "getBillItems", {
              requestorInfo: createRequestor(requestor, waiterId),
              sessionId: sessionId
            });
            
            const paidAmountAfterPayment = updatedBillItems?.data?.status === 200 ? updatedBillItems.data.data.billItems.paidAmount : 0;
            const totalAmounAfterPayment = updatedBillItems?.data?.status === 200 ? updatedBillItems.data.data.billItems.totalAmount : 0;
            let remainderAmountAfterPayment = totalAmounAfterPayment - paidAmountAfterPayment;

            if (expectedRemainderAmountBeforePayment !== remainderAmountAfterPayment) {
              return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected the outstanding balance for the payment to be ${expectedRemainderAmountBeforePayment} but it is ${remainderAmountAfterPayment}. The initial 'totalAmount' and 'paidAmount' values before the payment were ${totalAmountBeforePayment} and ${paidAmountBeforePayment} and post payment are now ${totalAmounAfterPayment} and ${paidAmountAfterPayment}.`] };
            } else {
              return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `Successfully ignored an unsuccessful payment. The initial 'totalAmount' and 'paidAmount' values before the payment were ${totalAmountBeforePayment} and ${paidAmountBeforePayment} and post payment are now ${totalAmounAfterPayment} and ${paidAmountAfterPayment}.` }
            }
          } else {
            return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, `a '${EPOS_ERROR_PAYMENT_NOT_RECORDED}' error response`)}`] }
          }
        case TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL:
        case TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL:
        case TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_GRATUITY:
        case TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_GRATUITY:
        case TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_CASHBACK:
        case TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_CASHBACK:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`Expected a successful payment but received a '${responseBody.errorCode}' error response`] };
        default: break;
      }
    }
  }
  catch (error) {
    console.log(error)
  };
}

async function testUnlockSessionRequest(accountName, requestor, sessionId, precondition, waiterId) {
  try {
    switch (precondition) {
      case TEST_PRE_CONDITION_SESSION_LOCKED:
        await testLockSessionRequest(accountName, requestor, sessionId, undefined, waiterId);
        break;
      case TEST_PRE_CONDITION_SESSION_UNLOCKED:
        await testUnlockSessionRequest(accountName, requestor, sessionId, undefined, waiterId);
        break;
      default:
        break;
    }
    const requestBody = {
      requestorInfo: createRequestor(requestor, waiterId),
      sessionId: sessionId
    }
    const response = await sendTablesRequest(accountName, "unlockSession", requestBody);
    let responseBody;

    if (response.data.status === 200) {
      responseBody = response.data.data;
      switch (precondition) {
        case TEST_PRE_CONDITION_SESSION_NON_EXISTING:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`a '${EPOS_ERROR_SESSION_NO_SUCH_SESSION}' error response but received session`] };
        case TEST_PRE_CONDITION_SESSION_LOCKED:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: "Successfully unlocked session" };
        case TEST_PRE_CONDITION_SESSION_UNLOCKED:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`a '${EPOS_ERROR_SESSION_NOT_LOCKED}' error response but received session`] };
        default:
          return { result: "PRE_CONDITION" }
      }
    } else {
      responseBody = response.data.error;
      switch (precondition) {
        case TEST_PRE_CONDITION_SESSION_NON_EXISTING:
          return responseBody.errorCode === EPOS_ERROR_SESSION_NO_SUCH_SESSION ?
            { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `${createResponse(true, response)}` } :
            { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, `a '${EPOS_ERROR_SESSION_NO_SUCH_SESSION}' error response`)}`] };
        case TEST_PRE_CONDITION_SESSION_UNLOCKED:
          return responseBody.errorCode === EPOS_ERROR_SESSION_NOT_LOCKED ?
            { request: requestBody, response: responseBody, result: TEST_STATUS_PASSED, data: `${createResponse(true, response)}` } :
            { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, `a '${EPOS_ERROR_SESSION_NOT_LOCKED}' error response`)}`] };
        case TEST_PRE_CONDITION_SESSION_LOCKED:
          return { request: requestBody, response: responseBody, result: TEST_STATUS_FAILED, reasons: [`${createResponse(false, response, "to successfully unlock the session")}`] };
        default:
          return { result: "PRE_CONDITION" }
      }
    }
  }
  catch (error) {
    console.log(error)
  };
}

function createResponse(wasSuccessful, response, expectation) {
  return wasSuccessful ? `Expected and received a '${response.data.error.errorCode}' error response with the error message: '${response.data.error.errorMessage}'`
    : `Expected ${expectation} but received a '${response.data.error.errorCode}' error response with error message: '${response.data.error.errorMessage}'`;
}

async function sendTablesRequest(accountName, method, data) {
  return await axios({
    method: 'post',
    url: '/sendRequest',
    data: {
      accountName: accountName,
      method: method,
      data: data
    }
  });
}

export { testListTableRequest, testListSessionsRequest, testListBillItemsRequest, testLockSessionRequest, testUnlockSessionRequest, testRecordPaymentRequest, testGetSession, testGetTable, sendTablesRequest, createRequestor, sleep };
