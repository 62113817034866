import React, { useCallback, useEffect, useRef } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { TEST_STATUS_FAILED, TEST_STATUS_NOT_SUPPORTED, TEST_STATUS_PASSED } from './Constants';

function TestCaseHeader(props) {

  const { testCase, setHeaderVisible, isSending, setIsSending, runTests, filterTestResultsByType, filterPassed, filterFailed, filterNotSupported } = props;
  const headerVisible = testCase.headerVisible;
  const isMounted = useRef(true)

  // set isMounted to false when we unmount the component
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const runTestWithCallback = useCallback(async () => {
    if (isSending) return
    setIsSending(true)
    await runTests()
    if (isMounted.current)
      setIsSending(false)
  }, [isSending, setIsSending, runTests])

  return (
    <div className="test-case-header no-select">
      <div className="test-case-header-title">
        {testCase.name}
      </div>

      <div className="test-case-header-results">
        <div className={`test-case-header-results-split ${testCase.notSupportedCount > 0 ? "not-supported-text-color" : ""}`}>{testCase.notSupportedCount}</div>
        <div onClick={() => filterTestResultsByType(testCase, TEST_STATUS_NOT_SUPPORTED)} 
            className={`test-case-header-results-icon test-case-result test-case-result-not-supported-image ${filterNotSupported === true ? "active" : ""}`}></div>
        <div className={`test-case-header-results-split ${testCase.failedCount > 0 ? "failed-text-color" : ""}`}>{testCase.failedCount}</div>
        <div onClick={() => filterTestResultsByType(testCase, TEST_STATUS_FAILED)} 
            className={`test-case-header-results-icon test-case-result test-case-result-failed-image ${filterFailed === true ? "active" : ""}`}></div>
        <div className={`test-case-header-results-split ${testCase.passedCount === testCase.testCases.length ? "passed-text-color" : ""}`}>{testCase.passedCount}</div>
        <div onClick={() => filterTestResultsByType(testCase, TEST_STATUS_PASSED)} 
            className={`test-case-header-results-icon test-case-result test-case-result-passed-image ${filterPassed === true || testCase.passedCount === testCase.testCases.length ? "active" : ""}`}></div>
      </div>
      <button onClick={() => setHeaderVisible()} className={`test-case-header-button ${headerVisible === true ? "test-case-header-button-expand-up" : "test-case-header-button-expand-down"}`} />
      {isSending === false &&
        <button onClick={() => runTestWithCallback()} className="test-case-header-button test-case-retry-button" />
      }
      {isSending === true &&
        <div style={{ "float": "right", "margin": "10px 5px", "width": "20px" }}>
          <PuffLoader
            color="#2196F3"
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      }
      <div className="clear" />
    </div>
  );
}

export { TestCaseHeader };