import React, { useCallback, useEffect, useState } from 'react';
import { ListTables } from '../TestSuite/ListTables';
import { ListSessions } from '../TestSuite/ListSessions';
import { ListBillItems } from '../TestSuite/ListBillItems';
import { LockSession } from '../TestSuite/LockSession';
import { UnlockSession } from '../TestSuite/UnlockSession';
import { TABLE_STATUS_AVAILABLE, TABLE_STATUS_NOT_IN_USE, TABLE_STATUS_OCCUPIED, TABLE_STATUS_PENDING_AVAILABLE, TEST_PRE_CONDITION_PAYMENT_DUPLICATE, TEST_STATUS_FAILED, TEST_STATUS_NOT_SUPPORTED, TEST_STATUS_PASSED, TEST_STATUS_UNTESTED } from '../shared/Constants';
import { testGetSession, testGetTable, testListBillItemsRequest, testListSessionsRequest, testListTableRequest, testLockSessionRequest, testRecordPaymentRequest, testUnlockSessionRequest } from '../shared/API';
import { GetSession } from '../TestSuite/GetSession';
import { GetTable } from '../TestSuite/GetTable';
import { RecordPayment } from '../TestSuite/RecordPayment';

function TestCases(props) {
  const { testSuite, setTestSuite, isAllRunning, setIsAllRunning, accountName, waiterId, waiterEnabled, setConnected, setValidated } = props;
  const [didLoad, setDidLoad] = useState(false);

  useEffect(() => {
    if (!didLoad) {
      resetTestResult(testSuite.getSession);
      resetTestResult(testSuite.getTable);
      resetTestResult(testSuite.listBillItems);
      resetTestResult(testSuite.listSessions);
      resetTestResult(testSuite.listTables);
      resetTestResult(testSuite.lockSession);
      resetTestResult(testSuite.unlockSession);
      resetTestResult(testSuite.recordPayment);
      setTestSuite({ ...testSuite });
      setDidLoad(true);
    }
  }, [didLoad, testSuite, setTestSuite])

  function resetTestResult(testSection) {
    testSection.passedCount = 0;
    testSection.failedCount = 0;
    for (let i = 0; i < testSection.testCases.length; i++) {
      if (testSection.testCases[i].result !== TEST_STATUS_NOT_SUPPORTED) {
        testSection.testCases[i].result = TEST_STATUS_UNTESTED;
        testSection.testCases[i].request = undefined;
        testSection.testCases[i].response = undefined;
        testSection.testCases[i].successData = undefined;
        testSection.testCases[i].reasons = [];
      }
    }
  }

  function updateTestCounters(testSection, testCases) {
    testSection.notSupportedCount = testCases.filter(test => test.result === TEST_STATUS_NOT_SUPPORTED).length;
    testSection.failedCount = testCases.filter(test => test.result === TEST_STATUS_FAILED).length;
    testSection.passedCount = testCases.filter(test => test.result === TEST_STATUS_PASSED).length;
  }

  function setTestSection(testSection) {
    testSection.failedCount = 0;
    testSection.passedCount = 0;
  }

  const runGetTableTests = useCallback(async () => {
    const testSection = testSuite.getTable;
    const testCases = testSection.testCases;

    setTestSection(testSection)
    resetTestResult(testSection)

    for (let i = 0; i < testCases.length; i++) {
      if (testCases[i].result !== TEST_STATUS_NOT_SUPPORTED) {
        let response = waiterEnabled === true ?
          await testGetTable(accountName, testCases[i].requestor, testCases[i].preconditions, testCases[i].params.name, waiterId) :
          await testGetTable(accountName, testCases[i].requestor, testCases[i].preconditions, testCases[i].params.name);
        if (response.result) {
          testCases[i].request = response.request;
          testCases[i].response = response.response;
          testCases[i].result = response.result === TEST_STATUS_PASSED ? TEST_STATUS_PASSED : TEST_STATUS_FAILED;
          testCases[i].reasons = response.result === TEST_STATUS_FAILED ? response.reasons : "";
          testCases[i].successData = response.data ? response.data : "";
        }
        updateTestCounters(testSection, testCases);
        setTestSuite({ ...testSuite });
      }
    }
    setTestSuite({ ...testSuite });
  }, [accountName, setTestSuite, testSuite, waiterEnabled, waiterId])

  const runGetSessionTests = useCallback(async () => {
    const testSection = testSuite.getSession;
    const testCases = testSection.testCases;

    setTestSection(testSection)
    resetTestResult(testSection)

    for (let i = 0; i < testCases.length; i++) {
      if (testCases[i].result !== TEST_STATUS_NOT_SUPPORTED) {
        let response = waiterEnabled === true ?
          await testGetSession(accountName, testCases[i].requestor, testCases[i].preconditions, testCases[i].params.sessionId, waiterId) :
          await testGetSession(accountName, testCases[i].requestor, testCases[i].preconditions, testCases[i].params.sessionId);
        if (response.result) {
          testCases[i].request = response.request;
          testCases[i].response = response.response;
          testCases[i].result = response.result === TEST_STATUS_PASSED ? TEST_STATUS_PASSED : TEST_STATUS_FAILED;
          testCases[i].reasons = response.result === TEST_STATUS_FAILED ? response.reasons : "";
          testCases[i].successData = response.data ? response.data : "";
        }
        updateTestCounters(testSection, testCases);
        setTestSuite({ ...testSuite });
      }
    }
    setTestSuite({ ...testSuite });
  }, [accountName, setTestSuite, testSuite, waiterEnabled, waiterId])

  const runListBillItemsTests = useCallback(async () => {
    const testSection = testSuite.listBillItems;
    const testCases = testSection.testCases;

    setTestSection(testSection)
    resetTestResult(testSection);

    const totalBillItems = testSection.preTests
      .filter(preTest => preTest.data === undefined)[0].rawData.billItems
      .map(billItem => billItem.sessionId);

    const twoValidSessionIds = testSection.preTests[1].rawData.billItems
      .map(billItem => billItem.sessionId);

    for (let i = 0; i < testCases.length; i++) {
      if (testCases[i].result !== TEST_STATUS_NOT_SUPPORTED) {
        let response = waiterEnabled === true ?
          await testListBillItemsRequest(accountName, testCases[i].requestor, testCases[i].params, testCases[i].preconditions, twoValidSessionIds, totalBillItems, waiterId) :
          await testListBillItemsRequest(accountName, testCases[i].requestor, testCases[i].params, testCases[i].preconditions, twoValidSessionIds, totalBillItems);
        if (response.result) {
          testCases[i].request = response.request;
          testCases[i].response = response.response;
          testCases[i].result = response.result === TEST_STATUS_PASSED ? TEST_STATUS_PASSED : TEST_STATUS_FAILED;
          testCases[i].reasons = response.result === TEST_STATUS_FAILED ? response.reasons : "";
          testCases[i].successData = response.data ? response.data : "";
        }
        updateTestCounters(testSection, testCases);
        setTestSuite({ ...testSuite });
      }
    }
    setTestSuite({ ...testSuite });
  }, [accountName, setTestSuite, testSuite, waiterEnabled, waiterId])

  const runListSessionsTests = useCallback(async () => {
    const testSection = testSuite.listSessions;
    const testCases = testSection.testCases;
    const preTests = testSection.preTests;
    setTestSection(testSection)
    resetTestResult(testSection);

    const totalSessions = preTests[0].rawData?.sessions ? preTests[0].rawData.sessions.map(session => session.id) : 0;
    const payableSessions = preTests[1].rawData?.sessions ? preTests[1].rawData.sessions.map(session => session.id) : 0;
    const nonPayableSessions = preTests[3].rawData?.sessions ? preTests[3].rawData.sessions.map(session => session.id) : 0;
    const finishedSessions = preTests[4].rawData?.sessions ? preTests[4].rawData.sessions.map(session => session.id) : 0;
    const unfinishedSessions = preTests[5].rawData?.sessions ? preTests[5].rawData.sessions.map(session => session.id) : 0;
    const sessionsWithTable = preTests[6].rawData?.sessions ? preTests[6].rawData.sessions.map(session => session.id) : 0;
    const sessionsWithoutTable = preTests[7].rawData?.sessions ? preTests[7].rawData.sessions.map(session => session.id) : 0;
    const sessionWithTableName = preTests[8].rawData?.sessions ? preTests[8].rawData.sessions.map(session => session.id) : 0;

    for (let i = 0; i < testCases.length; i++) {
      if (testCases[i].result !== TEST_STATUS_NOT_SUPPORTED) {
        let response = waiterEnabled === true ?
          await testListSessionsRequest(accountName, testCases[i].requestor, testCases[i].params, totalSessions, payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions, sessionsWithTable, sessionsWithoutTable, sessionWithTableName, waiterId) :
          await testListSessionsRequest(accountName, testCases[i].requestor, testCases[i].params, totalSessions, payableSessions, nonPayableSessions, finishedSessions, unfinishedSessions, sessionsWithTable, sessionsWithoutTable, sessionWithTableName);
        if (response.result) {
          testCases[i].request = response.request;
          testCases[i].response = response.response;
          testCases[i].result = response.result === TEST_STATUS_PASSED ? TEST_STATUS_PASSED : TEST_STATUS_FAILED;
          testCases[i].reasons = response.result === TEST_STATUS_FAILED ? response.reasons : "";
          testCases[i].successData = response.data ? response.data : "";
        }
        updateTestCounters(testSection, testCases);
        setTestSuite({ ...testSuite });
      }
    }
    setTestSuite({ ...testSuite });
  }, [accountName, setTestSuite, testSuite, waiterEnabled, waiterId])

  const runListTablesTests = useCallback(async () => {
    const testSection = testSuite.listTables;
    const testCases = testSection.testCases;
    const preTests = testSection.preTests;
    setTestSection(testSection)
    resetTestResult(testSection);

    const rawTablesLength  = preTests[0].rawData?.tables?.length > 0 ? preTests[0].rawData?.tables?.length : 0;
    const availableTables = rawTablesLength > 0 ? preTests[0].rawData.tables.filter(table => table.status === TABLE_STATUS_AVAILABLE).length : 0;
    const pendingTables = rawTablesLength > 0 ? preTests[0].rawData.tables.filter(table => table.status === TABLE_STATUS_PENDING_AVAILABLE).length : 0;
    const occupiedTables = rawTablesLength > 0 ? preTests[0].rawData.tables.filter(table => table.status === TABLE_STATUS_OCCUPIED).length : 0;
    const notInUseTables = rawTablesLength > 0 ? preTests[0].rawData.tables.filter(table => table.status === TABLE_STATUS_NOT_IN_USE).length : 0;

    for (let i = 0; i < testCases.length; i++) {
      if (testCases[i].result !== TEST_STATUS_NOT_SUPPORTED) {
        let response = waiterEnabled === true ?
          await testListTableRequest(accountName, testCases[i].requestor, testCases[i].statuses, rawTablesLength, availableTables, pendingTables, occupiedTables, notInUseTables, waiterId) :
          await testListTableRequest(accountName, testCases[i].requestor, testCases[i].statuses, rawTablesLength, availableTables, pendingTables, occupiedTables, notInUseTables);
        if (response.result) {
          testCases[i].request = response.request;
          testCases[i].response = response.response;
          testCases[i].result = response.result === TEST_STATUS_PASSED ? TEST_STATUS_PASSED : TEST_STATUS_FAILED;
          testCases[i].reasons = response.result === TEST_STATUS_FAILED ? response.reasons : "";
          testCases[i].successData = response.data ? response.data : "";
        }
        updateTestCounters(testSection, testCases);
        setTestSuite({ ...testSuite });
      }
    }
    setTestSuite({ ...testSuite });
  }, [accountName, setTestSuite, testSuite, waiterEnabled, waiterId])

  const runLockSessionTests = useCallback(async () => {
    const testSection = testSuite.lockSession;
    const testCases = testSection.testCases;
    setTestSection(testSection)
    resetTestResult(testSection);

    for (let i = 0; i < testCases.length; i++) {
      if (testCases[i].result !== TEST_STATUS_NOT_SUPPORTED) {
        let response = waiterEnabled === true ?
          await testLockSessionRequest(accountName, testCases[i].requestor, testCases[i].params.sessionId, testCases[i].preconditions, waiterId) :
          await testLockSessionRequest(accountName, testCases[i].requestor, testCases[i].params.sessionId, testCases[i].preconditions);
        if (response.result) {
          testCases[i].request = response.request;
          testCases[i].response = response.response;
          testCases[i].result = response.result === TEST_STATUS_PASSED ? TEST_STATUS_PASSED : TEST_STATUS_FAILED;
          testCases[i].reasons = response.result === TEST_STATUS_FAILED ? response.reasons : "";
          testCases[i].successData = response.data ? response.data : "";
        }
        updateTestCounters(testSection, testCases);
        setTestSuite({ ...testSuite });
      }
    }
    setTestSuite({ ...testSuite });
  }, [accountName, setTestSuite, testSuite, waiterEnabled, waiterId])

  const runUnlockSessionTests = useCallback(async () => {
    const testSection = testSuite.unlockSession;
    const testCases = testSection.testCases;
    setTestSection(testSection)
    resetTestResult(testSection)

    for (let i = 0; i < testCases.length; i++) {
      if (testCases[i].result !== TEST_STATUS_NOT_SUPPORTED) {
        let response = waiterEnabled === true ?
          await testUnlockSessionRequest(accountName, testCases[i].requestor, testCases[i].params.sessionId, testCases[i].preconditions, waiterId) :
          await testUnlockSessionRequest(accountName, testCases[i].requestor, testCases[i].params.sessionId, testCases[i].preconditions);
        if (response.result) {
          testCases[i].request = response.request;
          testCases[i].response = response.response;
          testCases[i].result = response.result === TEST_STATUS_PASSED ? TEST_STATUS_PASSED : TEST_STATUS_FAILED;
          testCases[i].reasons = response.result === TEST_STATUS_FAILED ? response.reasons : "";
          testCases[i].successData = response.data ? response.data : "";
        }
        testCases[i].request = response.request;
        updateTestCounters(testSection, testCases);
        setTestSuite({ ...testSuite });
      }
    }
    setTestSuite({ ...testSuite });
  }, [accountName, setTestSuite, testSuite, waiterEnabled, waiterId])

  const runRecordPaymentTests = useCallback(async () => {
    const testSection = testSuite.recordPayment;
    const testCases = testSection.testCases;
    setTestSection(testSection)
    resetTestResult(testSection)

    for (let i = 0; i < testCases.length; i++) {
      if (testCases[i].result !== TEST_STATUS_NOT_SUPPORTED) {
        let duplicatePaymentId = testCases[i].preconditions.includes(TEST_PRE_CONDITION_PAYMENT_DUPLICATE) ? testCases[i - 3].request.payment.id : undefined;
        let duplicateAttemptedIsoString = testCases[i].preconditions.includes(TEST_PRE_CONDITION_PAYMENT_DUPLICATE) ? testCases[i - 3].request.payment.attemptedAt : undefined;
        let response = waiterEnabled === true ?
          await testRecordPaymentRequest(accountName, testCases[i].requestor, testCases[i].params.sessionId, duplicatePaymentId, duplicateAttemptedIsoString, testCases[i].preconditions, waiterId) :
          await testRecordPaymentRequest(accountName, testCases[i].requestor, testCases[i].params.sessionId, duplicatePaymentId, duplicateAttemptedIsoString, testCases[i].preconditions);
        if (response.result) {
          testCases[i].request = response.request;
          testCases[i].response = response.response;
          testCases[i].result = response.result === TEST_STATUS_PASSED ? TEST_STATUS_PASSED : TEST_STATUS_FAILED;
          testCases[i].reasons = response.result === TEST_STATUS_FAILED ? response.reasons : "";
          testCases[i].successData = response.data ? response.data : "";
        }
        testCases[i].request = response.request;
        updateTestCounters(testSection, testCases);
        setTestSuite({ ...testSuite });
      }
    }
    setTestSuite({ ...testSuite });
  }, [accountName, setTestSuite, testSuite, waiterEnabled, waiterId])

  const runAllTests = useCallback(async () => {
    setIsAllRunning(true)
    resetTestResult(testSuite.getSession);
    resetTestResult(testSuite.getTable);
    resetTestResult(testSuite.listBillItems);
    resetTestResult(testSuite.listSessions);
    resetTestResult(testSuite.listTables);
    resetTestResult(testSuite.lockSession);
    resetTestResult(testSuite.unlockSession);
    resetTestResult(testSuite.recordPayment);
    setTestSuite({ ...testSuite });
    try {
      await runGetSessionTests();
      await runGetTableTests();
      await runListBillItemsTests();
      await runListSessionsTests();
      await runListTablesTests();
      await runLockSessionTests();
      await runUnlockSessionTests();
      await runRecordPaymentTests();
    } catch (error) {
      if (error.error?.errorCode === "CONNECT_NO_EPOS_CONNECTED") {
        setConnected(false)
        setValidated(false)
      }
    }
    setIsAllRunning(false)
  }, [setIsAllRunning, setTestSuite, testSuite, setConnected, setValidated, runGetTableTests, runGetSessionTests, runListBillItemsTests, runListSessionsTests, runListTablesTests, runLockSessionTests, runUnlockSessionTests, runRecordPaymentTests])

  function showJsonPayload(testCases, toggleIndex) {
    for (let i = 0; i < testCases.length; i++) {
      if (i === toggleIndex) {
        testCases[i].resultActive = testCases[i].resultActive ? !testCases[i].resultActive : true;
      } else {
        testCases[i].resultActive = false;
      }
    }
    setTestSuite({ ...testSuite });
  }

  function filterTestResultsByType(testCase, testResultType) {
    switch (testResultType) {
      case TEST_STATUS_PASSED:
        if (testCase.passedCount > 0 && testCase.passedCount !== testCase.testCases.length) {
          testCase.filterPassed = testCase.filterPassed ? !testCase.filterPassed : true;
          testCase.filterFailed = false;
          testCase.filterNotSupported = false;
          testCase.filteredTestCases = (testCase.filterPassed === true) ?
            testCase.testCases.filter(testCase => testCase.result === TEST_STATUS_PASSED) :
            testCase.testCases
        }
        break;
      case TEST_STATUS_FAILED:
        if (testCase.failedCount > 0) {
          testCase.filterPassed = false;
          testCase.filterFailed = testCase.filterFailed ? !testCase.filterFailed : true;
          testCase.filterNotSupported = false;
          testCase.filteredTestCases = (testCase.filterFailed === true) ?
            testCase.testCases.filter(testCase => testCase.result === TEST_STATUS_FAILED) :
            testCase.testCases
        }
        break;
      case TEST_STATUS_NOT_SUPPORTED:
        if (testCase.notSupportedCount > 0) {
          testCase.filterPassed = false;
          testCase.filterFailed = false;
          testCase.filterNotSupported = testCase.filterNotSupported ? !testCase.filterNotSupported : true;
          testCase.filteredTestCases = (testCase.filterNotSupported === true) ?
            testCase.testCases.filter(testCase => testCase.result === TEST_STATUS_NOT_SUPPORTED) :
            testCase.testCases
        }
        break;
      default:
        testCase.filterPassed = false;
        testCase.filterFailed = false;
        testCase.filterNotSupported = false;
        testCase.filteredTestCases = testCase.testCases;
        break;
    }
    setTestSuite({ ...testSuite });
  }

  return (
    <div id="test-cases">
      <GetSession
        testSuite={testSuite}
        setTestSuite={setTestSuite}
        runTests={runGetSessionTests}
        showJsonPayload={showJsonPayload}
        filterTestResultsByType={filterTestResultsByType}
      />
      <GetTable
        testSuite={testSuite}
        setTestSuite={setTestSuite}
        runTests={runGetTableTests}
        showJsonPayload={showJsonPayload}
        filterTestResultsByType={filterTestResultsByType}
      />
      <ListBillItems
        testSuite={testSuite}
        setTestSuite={setTestSuite}
        runTests={runListBillItemsTests}
        showJsonPayload={showJsonPayload}
        filterTestResultsByType={filterTestResultsByType}
      />
      <ListSessions
        testSuite={testSuite}
        setTestSuite={setTestSuite}
        runTests={runListSessionsTests}
        showJsonPayload={showJsonPayload}
        filterTestResultsByType={filterTestResultsByType}
      />
      <ListTables
        testSuite={testSuite}
        setTestSuite={setTestSuite}
        runTests={runListTablesTests}
        showJsonPayload={showJsonPayload}
        filterTestResultsByType={filterTestResultsByType}
      />
      <LockSession
        testSuite={testSuite}
        setTestSuite={setTestSuite}
        runTests={runLockSessionTests}
        showJsonPayload={showJsonPayload}
        filterTestResultsByType={filterTestResultsByType}
      />
      <UnlockSession
        testSuite={testSuite}
        setTestSuite={setTestSuite}
        runTests={runUnlockSessionTests}
        showJsonPayload={showJsonPayload}
        filterTestResultsByType={filterTestResultsByType}
      />
      <RecordPayment
        testSuite={testSuite}
        setTestSuite={setTestSuite}
        runTests={runRecordPaymentTests}
        showJsonPayload={showJsonPayload}
        filterTestResultsByType={filterTestResultsByType}
      />
      <button disabled={isAllRunning === true} onClick={() => runAllTests()} className="login100-btn" style={{ "width": "calc(100% - 20px)", "margin": "30px auto" }}>Run Tests</button>
    </div>
  );
}

export { TestCases };