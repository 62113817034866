import { PRE_TEST_DATA_TYPE_TEXT, REQUESTOR_TYPE_CARD_MACHINE, REQUESTOR_TYPE_CONSUMER_DEVICE, TABLE_STATUS_AVAILABLE, TABLE_STATUS_NOT_IN_USE, TABLE_STATUS_OCCUPIED, TABLE_STATUS_PENDING_AVAILABLE, TEST_LIST_BILL_ITEMS_TEST_FIRST_SESSION_WITH_BILL, TEST_LIST_BILL_ITEMS_TEST_ONE_NON_EXISTING_SESSION, TEST_LIST_BILL_ITEMS_TEST_ONE_SESSION_WITH_BILL_WITH_ONE_NON_EXISTING_SESSION, TEST_LIST_BILL_ITEMS_TEST_SECOND_SESSION_WITH_BILL, TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL, TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL_WITH_ONE_NON_EXISTING_SESSIONS, TEST_STATUS_UNTESTED, TEST_PRE_CONDITION_SESSION_NON_EXISTING, TEST_PRE_CONDITION_SESSION_LOCKED, TEST_PRE_CONDITION_SESSION_UNLOCKED, TEST_PRE_CONDITION_SESSION_FINISHED, TEST_PRE_CONDITION_TABLE_NON_EXISTING, TEST_PRE_CONDITION_PAYMENT_CP_CANCELLED, TEST_PRE_CONDITION_PAYMENT_CP_DECLINED, TEST_PRE_CONDITION_PAYMENT_CP_UNKNOWN, TEST_PRE_CONDITION_PAYMENT_RP_CANCELLED, TEST_PRE_CONDITION_PAYMENT_RP_UNKNOWN, TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL, TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL, TEST_PRE_CONDITION_PAYMENT_DUPLICATE, TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_GRATUITY, TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_GRATUITY, TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_CASHBACK, TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITH_ITEMS, TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITHOUT_ITEMS, TEST_LIST_BILL_ITEMS_TEST_NO_PARAMS } from "../components/shared/Constants";

export const initialState = {
  connected: false,
  validated: false,
  isValidating: false,
  isConnecting: false,
  isAllRunning: false,
  notification: {
    visible: false,
    message: ""
  },
  accountName: "",
  waiterId: "",
  waiterEnabled: false,
  apiKey: "Payment1",
  testSuite: {
    getSession: {
      passedCount: 0,
      failedCount: 0,
      notSupportedCount: 0,
      headerVisible: false,
      name: "GetSession",
      preTests: [
        {
          check: "Check for the ability to retrieve a session",
          data: { sessionId: "11111111-1111-1111-1111-111111111111" },
          optional: false,
          result: TEST_STATUS_UNTESTED
        },
      ],
      testCases: [
        { index: 1, params: { sessionId: "00000000-0000-0000-0000-000000000000" }, preconditions: TEST_PRE_CONDITION_SESSION_NON_EXISTING, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 2, params: [], preconditions: TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITH_ITEMS, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 3, params: [], preconditions: TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITHOUT_ITEMS, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 4, params: [], preconditions: TEST_PRE_CONDITION_SESSION_FINISHED, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 5, params: { sessionId: "00000000-0000-0000-0000-000000000000" }, preconditions: TEST_PRE_CONDITION_SESSION_NON_EXISTING, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 6, params: [], preconditions: TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITH_ITEMS, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 7, params: [], preconditions: TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITHOUT_ITEMS, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 8, params: [], preconditions: TEST_PRE_CONDITION_SESSION_FINISHED, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
      ],
    },
    getTable: {
      passedCount: 0,
      failedCount: 0,
      notSupportedCount: 0,
      headerVisible: false,
      name: "GetTable",
      preTests: [
        {
          check: "Check for the ability to retrieve a table",
          data: { name: "1" },
          optional: false,
          result: TEST_STATUS_UNTESTED
        },
      ],
      testCases: [
        { index: 1, params: {name: "Table 9999999" }, preconditions: TEST_PRE_CONDITION_TABLE_NON_EXISTING, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 2, params: [], preconditions: TABLE_STATUS_AVAILABLE, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 3, params: [], preconditions: TABLE_STATUS_PENDING_AVAILABLE, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 4, params: [], preconditions: TABLE_STATUS_OCCUPIED, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 5, params: [], preconditions: TABLE_STATUS_NOT_IN_USE, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 6, params: {name: "Table 9999999" }, preconditions: TEST_PRE_CONDITION_TABLE_NON_EXISTING, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 7, params: [], preconditions: TABLE_STATUS_AVAILABLE, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 8, params: [], preconditions: TABLE_STATUS_PENDING_AVAILABLE, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 9, params: [], preconditions: TABLE_STATUS_OCCUPIED, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 10, params: [], preconditions: TABLE_STATUS_NOT_IN_USE, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
      ],
    },
    listTables: {
      passedCount: 0,
      failedCount: 0,
      notSupportedCount: 0,
      headerVisible: false,
      name: "ListTables",
      preTests: [
        {
          check: "Check for all tables without any parameters",
          optional: false,
          result: TEST_STATUS_UNTESTED
        },
        {
          check: "Check for at least one table where it is available",
          data: TABLE_STATUS_AVAILABLE,
          optional: false,
          result: TEST_STATUS_UNTESTED
        },
        {
          check: "Check for at least one table where it is pending available",
          data: TABLE_STATUS_PENDING_AVAILABLE,
          optional: true,
          result: TEST_STATUS_UNTESTED
        },
        {
          check: "Check for at least one table where it is occupied",
          data: TABLE_STATUS_OCCUPIED,
          optional: false,
          result: TEST_STATUS_UNTESTED
        },
        {
          check: "Check for at least one table where it is not in use",
          data: TABLE_STATUS_NOT_IN_USE,
          optional: true,
          result: TEST_STATUS_UNTESTED
        }
      ],
      testCases: [
        { index: 1, statuses: [], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 2, statuses: [TABLE_STATUS_AVAILABLE], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 3, statuses: [TABLE_STATUS_PENDING_AVAILABLE], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 4, statuses: [TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 5, statuses: [TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 6, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_PENDING_AVAILABLE], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 7, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 8, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 9, statuses: [TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 10, statuses: [TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 11, statuses: [TABLE_STATUS_NOT_IN_USE, TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 12, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 13, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 14, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_OCCUPIED, TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 15, statuses: [TABLE_STATUS_NOT_IN_USE, TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 16, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_OCCUPIED, TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 17, statuses: [], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 18, statuses: [TABLE_STATUS_AVAILABLE], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 19, statuses: [TABLE_STATUS_PENDING_AVAILABLE], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 20, statuses: [TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 21, statuses: [TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 22, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_PENDING_AVAILABLE], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 23, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 24, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 25, statuses: [TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 26, statuses: [TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 27, statuses: [TABLE_STATUS_NOT_IN_USE, TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 28, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 29, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 30, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_OCCUPIED, TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 31, statuses: [TABLE_STATUS_NOT_IN_USE, TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_OCCUPIED], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 32, statuses: [TABLE_STATUS_AVAILABLE, TABLE_STATUS_PENDING_AVAILABLE, TABLE_STATUS_OCCUPIED, TABLE_STATUS_NOT_IN_USE], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
      ]
    },
    listSessions: {
      passedCount: 0,
      failedCount: 0,
      notSupportedCount: 0,
      headerVisible: false,
      name: "ListSessions",
      preTests: [
        {
          check: "Check for all sessions without any parameters",
          optional: false,
          result: TEST_STATUS_UNTESTED,
        },
        {
          check: "Check for at least one session that is payable",
          data: { isPayable: true },
          optional: false,
          result: TEST_STATUS_UNTESTED,
        },
        {
          check: "Check for at least one session that is not payable and is not finished",
          data: { isPayable: false, isFinished: false },
          optional: true,
          result: TEST_STATUS_UNTESTED,
        },
        {
          check: "Check for at least one session that is not payable",
          data: { isPayable: false },
          optional: false,
          result: TEST_STATUS_UNTESTED,
        },
        {
          check: "Check for at least one session that is finished",
          data: { isFinished: true },
          optional: false,
          result: TEST_STATUS_UNTESTED,
        },
        {
          check: "Check for at least one session that is not finished",
          data: { isFinished: false },
          optional: false,
          result: TEST_STATUS_UNTESTED,
        },
        {
          check: "Check for at least one session that has a table",
          data: { hasTable: true },
          optional: false,
          result: TEST_STATUS_UNTESTED,
        },
        {
          check: "Check for at least one session that does not have a table",
          data: { hasTable: false },
          optional: true,
          result: TEST_STATUS_UNTESTED
        },
        {
          check: "Check for one session with the tableNames filter",
          data: { tableNames: ["1"] },
          optional: false,
          result: TEST_STATUS_UNTESTED,
        }
      ],
      testCases: [
        { index: 1, params: [], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 2, params: [{ isPayable: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 3, params: [{ isPayable: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 4, params: [{ isFinished: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 5, params: [{ isFinished: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 6, params: [{ hasTable: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 7, params: [{ hasTable: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 8, params: [{ isPayable: true, isFinished: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 9, params: [{ isPayable: true, isFinished: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 10, params: [{ isPayable: false, isFinished: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 11, params: [{ isPayable: false, isFinished: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 12, params: [{ isPayable: true, hasTable: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 13, params: [{ isPayable: true, hasTable: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 14, params: [{ isPayable: false, hasTable: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 15, params: [{ isPayable: false, hasTable: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 16, params: [{ isPayable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 17, params: [{ isPayable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 18, params: [{ isFinished: true, hasTable: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 19, params: [{ isFinished: true, hasTable: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 20, params: [{ isFinished: false, hasTable: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 21, params: [{ isFinished: false, hasTable: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 22, params: [{ isFinished: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 23, params: [{ isFinished: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 24, params: [{ hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 25, params: [{ hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 26, params: [{ isPayable: true, isFinished: true, hasTable: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 27, params: [{ isPayable: true, isFinished: true, hasTable: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 28, params: [{ isPayable: true, isFinished: false, hasTable: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 29, params: [{ isPayable: true, isFinished: false, hasTable: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 30, params: [{ isPayable: false, isFinished: true, hasTable: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 31, params: [{ isPayable: false, isFinished: true, hasTable: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 32, params: [{ isPayable: false, isFinished: false, hasTable: true }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 33, params: [{ isPayable: false, isFinished: false, hasTable: false }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 34, params: [{ isPayable: true, isFinished: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 35, params: [{ isPayable: true, isFinished: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 36, params: [{ isPayable: false, isFinished: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 37, params: [{ isPayable: false, isFinished: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 38, params: [{ isPayable: true, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 39, params: [{ isPayable: true, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 40, params: [{ isPayable: false, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 41, params: [{ isPayable: false, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 42, params: [{ hasTable: true, isFinished: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 43, params: [{ hasTable: true, isFinished: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 44, params: [{ hasTable: false, isFinished: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 45, params: [{ hasTable: false, isFinished: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 46, params: [{ isPayable: true, isFinished: true, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 47, params: [{ isPayable: true, isFinished: true, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 48, params: [{ isPayable: true, isFinished: false, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 49, params: [{ isPayable: true, isFinished: false, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 50, params: [{ isPayable: false, isFinished: true, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 51, params: [{ isPayable: false, isFinished: true, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 52, params: [{ isPayable: false, isFinished: false, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 53, params: [{ isPayable: false, isFinished: false, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 54, params: [], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 55, params: [{ isPayable: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 56, params: [{ isPayable: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 57, params: [{ isFinished: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 58, params: [{ isFinished: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 59, params: [{ hasTable: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 60, params: [{ hasTable: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 61, params: [{ isPayable: true, isFinished: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 62, params: [{ isPayable: true, isFinished: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 63, params: [{ isPayable: false, isFinished: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 64, params: [{ isPayable: false, isFinished: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 65, params: [{ isPayable: true, hasTable: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 66, params: [{ isPayable: true, hasTable: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 67, params: [{ isPayable: false, hasTable: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 68, params: [{ isPayable: false, hasTable: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 69, params: [{ isPayable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 70, params: [{ isPayable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 71, params: [{ isFinished: true, hasTable: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 72, params: [{ isFinished: true, hasTable: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 73, params: [{ isFinished: false, hasTable: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 74, params: [{ isFinished: false, hasTable: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 75, params: [{ isFinished: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 76, params: [{ isFinished: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 77, params: [{ hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 78, params: [{ hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 79, params: [{ isPayable: true, isFinished: true, hasTable: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 80, params: [{ isPayable: true, isFinished: true, hasTable: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 81, params: [{ isPayable: true, isFinished: false, hasTable: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 82, params: [{ isPayable: true, isFinished: false, hasTable: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 83, params: [{ isPayable: false, isFinished: true, hasTable: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 84, params: [{ isPayable: false, isFinished: true, hasTable: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 85, params: [{ isPayable: false, isFinished: false, hasTable: true }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 86, params: [{ isPayable: false, isFinished: false, hasTable: false }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 87, params: [{ isPayable: true, isFinished: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 88, params: [{ isPayable: true, isFinished: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 89, params: [{ isPayable: false, isFinished: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 90, params: [{ isPayable: false, isFinished: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 91, params: [{ isPayable: true, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 92, params: [{ isPayable: true, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 93, params: [{ isPayable: false, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 94, params: [{ isPayable: false, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 95, params: [{ hasTable: true, isFinished: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 96, params: [{ hasTable: true, isFinished: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 97, params: [{ hasTable: false, isFinished: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 98, params: [{ hasTable: false, isFinished: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 99, params: [{ isPayable: true, isFinished: true, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 100, params: [{ isPayable: true, isFinished: true, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 101, params: [{ isPayable: true, isFinished: false, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 102, params: [{ isPayable: true, isFinished: false, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 103, params: [{ isPayable: false, isFinished: true, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 104, params: [{ isPayable: false, isFinished: true, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 105, params: [{ isPayable: false, isFinished: false, hasTable: true, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 106, params: [{ isPayable: false, isFinished: false, hasTable: false, tableNames: [] }], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
      ]
    },
    listBillItems: {
      passedCount: 0,
      failedCount: 0,
      notSupportedCount: 0,
      headerVisible: false,
      name: "ListBillItems",
      preTests: [
        {
          check: "Check all bill items without any parameters for two non-zero bill items",
          dataType: PRE_TEST_DATA_TYPE_TEXT,
          optional: false,
          result: TEST_STATUS_UNTESTED,
        },
        {
          check: "Check for bill items while querying for two specific bill items",
          data: { sessionIds: ["11111111-1111-1111-1111-111111111111", "22222222-2222-2222-2222-222222222222"] },
          optional: false,
          result: TEST_STATUS_UNTESTED,
        },
      ],
      testCases: [
        { index: 1, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_NO_PARAMS, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 2, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_FIRST_SESSION_WITH_BILL, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 3, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_SECOND_SESSION_WITH_BILL, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 4, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_ONE_NON_EXISTING_SESSION, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 5, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_ONE_SESSION_WITH_BILL_WITH_ONE_NON_EXISTING_SESSION, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 6, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 7, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL_WITH_ONE_NON_EXISTING_SESSIONS, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 8, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_NO_PARAMS, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 9, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_FIRST_SESSION_WITH_BILL, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 10, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_SECOND_SESSION_WITH_BILL, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 11, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_ONE_NON_EXISTING_SESSION, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 12, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_ONE_SESSION_WITH_BILL_WITH_ONE_NON_EXISTING_SESSION, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 13, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 14, params: "", preconditions: TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL_WITH_ONE_NON_EXISTING_SESSIONS, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
      ]
    },
    lockSession: {
      passedCount: 0,
      failedCount: 0,
      notSupportedCount: 0,
      headerVisible: false,
      name: "LockSession",
      testCases: [
        { index: 1, params: [], preconditions: TEST_PRE_CONDITION_SESSION_NON_EXISTING, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 2, params: [], preconditions: TEST_PRE_CONDITION_SESSION_LOCKED, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 3, params: [], preconditions: TEST_PRE_CONDITION_SESSION_UNLOCKED, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 4, params: [], preconditions: TEST_PRE_CONDITION_SESSION_NON_EXISTING, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 5, params: [], preconditions: TEST_PRE_CONDITION_SESSION_LOCKED, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 6, params: [], preconditions: TEST_PRE_CONDITION_SESSION_UNLOCKED, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
      ],
    },
    unlockSession: {
      passedCount: 0,
      failedCount: 0,
      notSupportedCount: 0,
      headerVisible: false,
      name: "UnlockSession",
      testCases: [
        { index: 1, params: [], preconditions: TEST_PRE_CONDITION_SESSION_NON_EXISTING, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 2, params: [], preconditions: TEST_PRE_CONDITION_SESSION_LOCKED, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 3, params: [], preconditions: TEST_PRE_CONDITION_SESSION_UNLOCKED, requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 4, params: [], preconditions: TEST_PRE_CONDITION_SESSION_NON_EXISTING, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 5, params: [], preconditions: TEST_PRE_CONDITION_SESSION_LOCKED, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 6, params: [], preconditions: TEST_PRE_CONDITION_SESSION_UNLOCKED, requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
      ]
    },
    recordPayment: {
      passedCount: 0,
      failedCount: 0,
      notSupportedCount: 0,
      headerVisible: false,
      name: "RecordPayment",
      preTests: [
        {
          check: "Check for the ability to make a single penny payment",
          data: { sessionId: "00000000-0000-0000-0000-000000000000" },
          optional: false,
          result: TEST_STATUS_UNTESTED,
        },
      ],
      testCases: [
        { index: 1, params: { sessionId: "00000000-0000-0000-0000-000000000000" }, preconditions: [TEST_PRE_CONDITION_SESSION_NON_EXISTING], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 2, params: [], preconditions: [TEST_PRE_CONDITION_SESSION_UNLOCKED], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 3, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 4, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_GRATUITY], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 5, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_GRATUITY], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 6, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_DUPLICATE], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 7, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_CP_CANCELLED], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 8, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_CP_DECLINED], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 9, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_CP_UNKNOWN], requestor: REQUESTOR_TYPE_CARD_MACHINE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 10, params: { sessionId: "00000000-0000-0000-0000-000000000000" }, preconditions: [TEST_PRE_CONDITION_SESSION_NON_EXISTING], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 11, params: [], preconditions: [TEST_PRE_CONDITION_SESSION_UNLOCKED], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 12, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 13, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_GRATUITY], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 14, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_CASHBACK], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 15, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_DUPLICATE], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 16, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_RP_CANCELLED], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
        { index: 17, params: [], preconditions: [TEST_PRE_CONDITION_PAYMENT_RP_UNKNOWN], requestor: REQUESTOR_TYPE_CONSUMER_DEVICE, result: TEST_STATUS_UNTESTED, reasons: [], successData: [] },
      ]
    }
  }
}