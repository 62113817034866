import React, { useState } from 'react';
import { TestCaseHeader } from '../shared/TestCaseHeader';
import { TestCaseRow } from '../shared/TestCaseRow';
import { TEST_PRE_CONDITION_SESSION_NO_PARMS } from '../shared/Constants';

function ListSessions(props) {
  const { testSuite, setTestSuite, runTests, showJsonPayload, filterTestResultsByType } = props;
  const [isSending, setIsSending] = useState(false)

  const testCase = testSuite.listSessions;
  const testCases = testCase.filteredTestCases ? testCase.filteredTestCases : testCase.testCases;
  const preTests = testCase.preTests;
  const headerVisible = testCase.headerVisible;

  function switchSessionFilter(key, sessionFilter) {
    switch (key.toString()) {
      case "isPayable":
        if (sessionFilter[key] === true) return "PAYABLE_SESSIONS"
        if (sessionFilter[key] === false) return "NON_PAYABLE_SESSIONS"
        break;
      case "isFinished":
        if (sessionFilter[key] === true) return "FINISHED_SESSIONS"
        if (sessionFilter[key] === false) return "UNFINISHED_SESSIONS"
        break;
      case "hasTable":
        if (sessionFilter[key] === true) return "SESSIONS_WITH_A_TABLE"
        if (sessionFilter[key] === false) return "SESSIONS_WITHOUT_A_TABLE"
        break;
      case "tableNames": return "SESSIONS_MATCHING_TABLE_NAMES_FILTER"
      default: break;
    }
  }

  const testCasesDiv = testCases.map((test, index) => {
    let preconditions = [];
    if (test.params.length >= 1) {
      for (let i = 0; i < test.params.length; i++) {
        for (let key of Object.keys(test.params[i])) {
          preconditions.push(switchSessionFilter(key, test.params[i]));
          if (key.toString() === "tableNames") {
            test.params[i][key] = preTests[8].data[Object.keys(preTests[8].data)[0]]
          }
        }
      }
    } else {
      preconditions = TEST_PRE_CONDITION_SESSION_NO_PARMS;
    }


    return (
      <TestCaseRow
        i={index}
        key={index}
        index={test.index}
        scenarioType="SESSIONS"
        preconditions={preconditions}
        testCases={testCases}
        requestor={test.requestor}
        request={test.request}
        response={test.response}
        result={test.result}
        requestActive={test.requestActive}
        responseActive={test.responseActive}
        resultActive={test.resultActive}
        reasons={test.reasons}
        isSending={isSending}
        showJsonPayload={showJsonPayload}
        successData={test.successData}
      />
    )
  })

  function setHeaderVisible() {
    testCase.headerVisible = !headerVisible;
    setTestSuite({ ...testSuite });
  }

  return (
    <div className="test-case-container">
      <TestCaseHeader
        testCase={testCase}
        setHeaderVisible={setHeaderVisible}
        isSending={isSending}
        setIsSending={setIsSending}
        runTests={runTests}
        filterPassed={testCase.filterPassed}
        filterFailed={testCase.filterFailed}
        filterNotSupported={testCase.filterNotSupported}
        filterTestResultsByType={filterTestResultsByType}
      />
      {headerVisible === true &&
        <div className='test-case-description'>
          <div className="test-case-header-details">
            <div className="pre-tests">
              <hr />
              {testCasesDiv}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export { ListSessions };
