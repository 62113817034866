import React, { useState } from 'react';
import { TestCaseHeader } from '../shared/TestCaseHeader';
import { TestCaseRow } from '../shared/TestCaseRow';

function GetSession(props) {
  const { testSuite, setTestSuite, runTests, showJsonPayload, filterTestResultsByType } = props;
  const [isSending, setIsSending] = useState(false)

  const testCase = testSuite.getSession;
  const testCases = testCase.filteredTestCases ? testCase.filteredTestCases : testCase.testCases;
  const headerVisible = testCase.headerVisible;

  const testCasesDiv = testCases.map((test, index) => {
    return (
      <TestCaseRow
        i={index}
        key={index}
        index={test.index}
        params={test.params}
        preconditions={test.preconditions}
        testCases={testCases}
        requestor={test.requestor}
        request={test.request}
        response={test.response}
        result={test.result}
        requestActive={test.requestActive}
        responseActive={test.responseActive}
        resultActive={test.resultActive}
        reasons={test.reasons}
        isSending={isSending}
        showJsonPayload={showJsonPayload}
        successData={test.successData}
      />
    )
  })

  function setHeaderVisible() {
    testCase.headerVisible = !headerVisible;
    setTestSuite({ ...testSuite });
  }

  return (
    <div className="test-case-container">
      <TestCaseHeader
        testCase={testCase}
        setHeaderVisible={setHeaderVisible}
        isSending={isSending}
        setIsSending={setIsSending}
        runTests={runTests}
        filterPassed={testCase.filterPassed}
        filterFailed={testCase.filterFailed}
        filterNotSupported={testCase.filterNotSupported}
        filterTestResultsByType={filterTestResultsByType}
      />
      {headerVisible === true &&
        <div className='test-case-description'>
          <div className="test-case-header-details">
            <div className="pre-tests">
              <hr />
              {testCasesDiv}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export { GetSession };
