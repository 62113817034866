export const TABLE_STATUS_NOT_IN_USE = "TABLE_STATUS_NOT_IN_USE";
export const TABLE_STATUS_PENDING_AVAILABLE = "TABLE_STATUS_PENDING_AVAILABLE";
export const TABLE_STATUS_AVAILABLE = "TABLE_STATUS_AVAILABLE";
export const TABLE_STATUS_OCCUPIED = "TABLE_STATUS_OCCUPIED";

export const REQUESTOR_TYPE_CARD_MACHINE = "REQUESTOR_TYPE_CARD_MACHINE";
export const REQUESTOR_TYPE_CONSUMER_DEVICE = "REQUESTOR_TYPE_CONSUMER_DEVICE";

export const TEST_STATUS_NOT_SUPPORTED = "NOT_SUPPORTED";
export const TEST_STATUS_UNTESTED = "UNTESTED";
export const TEST_STATUS_PASSED = "PASSED";
export const TEST_STATUS_FAILED = "FAILED";

export const PRE_TEST_DATA_TYPE_INPUT = "INPUT";
export const PRE_TEST_DATA_TYPE_TEXT = "TEXT";
export const PRE_TEST_DATA_TYPE_INPUT_UUID = "UUID";
export const PRE_TEST_DATA_TYPE_INPUT_STRING = "STRING";

export const TEST_LIST_BILL_ITEMS_TEST_NO_PARAMS = "ALL_SESSIONS_WITH_BILL_ITEMS";
export const TEST_LIST_BILL_ITEMS_TEST_FIRST_SESSION_WITH_BILL = "FIRST_SESSION_WITH_BILL_ITEMS";
export const TEST_LIST_BILL_ITEMS_TEST_SECOND_SESSION_WITH_BILL = "SECOND_SESSION_WITH_WITH_BILL_ITEMS";
export const TEST_LIST_BILL_ITEMS_TEST_ONE_NON_EXISTING_SESSION = "NON_EXISTING_SESSION";
export const TEST_LIST_BILL_ITEMS_TEST_ONE_SESSION_WITH_BILL_WITH_ONE_NON_EXISTING_SESSION = "TWO_SESSIONS_ONE_WITH_BILL_ITEMS_AND_ONE_NON_EXISTING";
export const TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL = "TWO_SESSIONS_WITH_BILL_ITEMS";
export const TEST_LIST_BILL_ITEMS_TEST_TWO_SESSIONS_WITH_BILL_WITH_ONE_NON_EXISTING_SESSIONS = "THREE_SESSIONS_TWO_WITH_BILL_ITEMS_AND_ONE_NON_EXISTING";

export const EPOS_ERROR_SESSION_NO_SUCH_SESSION = "SESSION_NO_SUCH_SESSION";
export const EPOS_ERROR_TABLE_NO_SUCH_TABLE = "TABLE_NO_SUCH_TABLE";
export const EPOS_ERROR_SESSION_ALREADY_LOCKED = "SESSION_ALREADY_LOCKED";
export const EPOS_ERROR_SESSION_NOT_LOCKED = "SESSION_NOT_LOCKED";

export const EPOS_ERROR_PAYMENT_ALREADY_RECORDED = "PAYMENT_ALREADY_RECORDED";
export const EPOS_ERROR_PAYMENT_NOT_RECORDED = "PAYMENT_NOT_RECORDED";

export const TEST_PRE_CONDITION_SESSION_NO_PARMS = "ALL_SESSIONS";
export const TEST_PRE_CONDITION_SESSION_NON_EXISTING = "NON_EXISTING_SESSION";
export const TEST_PRE_CONDITION_SESSION_UNLOCKED = "UNLOCKED_SESSION";
export const TEST_PRE_CONDITION_SESSION_LOCKED = "LOCKED_SESSION";
export const TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITH_ITEMS = "OPEN_SESSION_WITH_ITEMS";
export const TEST_PRE_CONDITION_SESSION_NEWLY_CREATED_WITHOUT_ITEMS = "OPEN_SESSION_WITHOUT_ITEMS";
export const TEST_PRE_CONDITION_SESSION_FINISHED = "FINISHED_SESSION";

export const TEST_PRE_CONDITION_TABLE_NO_PARAMS = "ALL_TABLES";
export const TEST_PRE_CONDITION_TABLE_NON_EXISTING = "NON_EXISTING_TABLE";

export const TEST_PRE_CONDITION_PAYMENT_DUPLICATE = "DUPLICATE_PAYMENT";
export const TEST_PRE_CONDITION_PAYMENT_CP_CANCELLED = "CARD_PRESENT_PAYMENT_STATUS_CANCELLED";
export const TEST_PRE_CONDITION_PAYMENT_CP_DECLINED = "CARD_PRESENT_PAYMENT_STATUS_DECLINED";
export const TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL = "CARD_PRESENT_PAYMENT_STATUS_SUCCESSFUL";
export const TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_GRATUITY = "CARD_PRESENT_SUCCESSFUL_STATUS_SUCCESSFUL_WITH_GRATUITY";
export const TEST_PRE_CONDITION_PAYMENT_CP_SUCCESSFUL_WITH_CASHBACK = "CARD_PRESENT_SUCCESSFUL_STATUS_SUCCESSFUL_WITH_CASHBACK";
export const TEST_PRE_CONDITION_PAYMENT_CP_UNKNOWN = "CARD_PRESENT_PAYMENT_STATUS_UNKNOWN";
export const TEST_PRE_CONDITION_PAYMENT_RP_CANCELLED = "REMOTE_PAYMENT_STATUS_CANCELLED";
export const TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL = "REMOTE_PAYMENT_STATUS_SUCCESSFUL";
export const TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_GRATUITY = "REMOTE_PAYMENT_STATUS_SUCCESSFUL_WITH_GRATUITY";
export const TEST_PRE_CONDITION_PAYMENT_RP_SUCCESSFUL_WITH_CASHBACK = "REMOTE_PAYMENT_STATUS_SUCCESSFUL_WITH_CASHBACK";
export const TEST_PRE_CONDITION_PAYMENT_RP_UNKNOWN = "REMOTE_PAYMENT_STATUS_UNKNOWN";

export const WS_CONNECTION_STATUS_CLOSED = "WS_CONNECTION_STATUS_CLOSED";
export const WS_CONNECTION_STATUS_FAILED = "WS_CONNECTION_STATUS_FAILED";
export const WS_CONNECTION_STATUS_OPEN = "WS_CONNECTION_STATUS_OPEN";
export const WS_CONNECTION_STATUS_CONNECTED = "WS_CONNECTION_STATUS_CONNECTED";
export const WS_CONNECTION_STATUS_DISCONNECTED = "WS_CONNECTION_STATUS_DISCONNECTED";